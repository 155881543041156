import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      SaveChanges: "Save changes",
      Edit: "Edit",
      Cancel: "Cancel",
      DefaultSearchGreeting: "Hi,",
      DefaultSearchDescription: "I am DeliwiAI and I find hidden talents and answers you need from your organization.",
      ExecutedSearchGreeting: "Got it, you are looking for:",
      ExecutedSearchDescription: '"{{searchString}}"',
      SearchFieldPlaceholder: "Tell me what kind of talent you need?",
      UserProfilePageTitle: "My Profile",
      UserProfileDetailsAboutMe: "About me",
      UserProfileDetailsWorkExperience: "Work experience",
      UserProfileDetailsSkills: "Skills",
      UserProfileDetailsEducationAndTrainings: "Education & trainings",
      UserProfileDetailsEducation: "Education",
      UserProfileDetailsMyInterests: "My interests",
      LanguageSkillLevel1: "Basics",
      LanguageSkillLevel2: "Professional",
      LanguageSkillLevel3: "Native or bilingual",
      SeeProfileButtonLabel: "Talent preview",
      TeamsAdminRoleRequired: "You need to be a Teams administrator to be able to enable this feature.",
      OperationInProgress: "Background operation is currently in progress.",
      TeamsBotEnabled: "Teams bot is enabled for your organization.",
      TeamsBotDisabled: "Teams bot is disabled for your organization.",
      PersonalInformation: "Personal information",
      FirstName: "First name",
      LastName: "Last name",
      PhoneNumber: "Phone number",
      Language: "Language",
      NativeLanguage: "Native language",
      EducationTraining: "Education & training",
      CompanyInformation: "Company information",
      JobInformation: "Job information",
      CompanyName: "Company name",
      CompanyCity: "Company city",
      CompanyCountry: "Company country",
      CompanyDepartment: "Company department",
      WorkingSpaceLocation: "Working space location",
      WorkingSpaceCountry: "Working space country",
      JobTitle: "Job title",
      Supervisor: "Supervisor",
      FutureAndDevelopment: "Future and development",
      WorkloadPredictionForNext3Months: "Workload prediction for next 3 months (%)",
      WhatWouldYouLikeToDoIn3Years: "What would you like to do in 3 years?",
      DreamPositionOrJobIn5Years: "Dream position or job in 5 years?",
      CurrentWork: "Current work",
      RecentProjects: "Recent projects",
      JobDescription: "Job description",
      ShortJobDescription: "Short job description",
      ShortDescription: "Short description",
      AddProject: "Add new project",
      RemoveProject: "Remove project",
      ProjectName: "Project name",
      NonRatedSkills: "Non rated skills",
      Basic: "Basic",
      Professional: "Professional",
      SpecialExpertise: "Special expertise",
      Level: "Level",
      RoleInProject: "Role in project",
      LanguageSkills: "Language skills",
      RemoveSkill: "Remove skill",
      AddSkill: "Add new skill",
      RemoveLanguageSkill: "Remove language skill",
      AddLanguageSkill: "Add new language skill",
      EducationDescription: "Education description",
      DegreeOrProgramme: "Degree or programme",
      FieldOfStudy: "Field of study",
      RemoveEducation: "Remove education",
      AddEducation: "Add new education",
      RemoveCertification: "Remove certification",
      AddCertification: "Add new certification",
      RemoveTraining: "Remove training",
      AddTraining: "Add new training",
      RemoveAward: "Remove award",
      AddAward: "Add new award",
      AiSearch: "AI Search",
      MyProfile: "My Profile",
      Myprofile: "My profile",
      ControlPanel: "Control Panel",
      LogOut: "Log Out",
      SignIn: "Sign In",
      AboutMe: "About me",
      WorkExperience: "Work experience",
      Skills: "Skills",
      EducationTrainings: "Education & Trainings",
      FutureDevelopment: "Future and development",
      CloseEdit: "Close edit",
      TeamsManagement: "Chatbot for Microsoft Teams",
      PreferredLanguage: "Preferred language",
      DateOfBirth: "Date of birth",
      FirstVisitStep1Label: "Welcome to the DeliwiAI",
      FirstVisitStep1Content: "If there's a skill or resource you need, it's likely someone in your organization has it. With DeliwiAI, you can find it easily and quickly.",
      FirstVisitStep2Label: "Find right people with just one search",
      FirstVisitStep2Content: "Write in your own words what kind of advice, insights or skilled resources you are looking for.",
      FirstVisitStep3Label: "Get the best matches as a response",
      FirstVisitStep3Content: "DeliwiAI will find and match the right people from your organization for your specific needs.",
      FirstVisitStep4Label: "Get to know the person and make contact",
      FirstVisitStep4Content: "Ask the person immediately for advice or help for your need.",
      FirstVisitStep1Button: "Let's see how it works",
      FirstVisitLastStepButton: "Start to use it!",
      Next: "Next",
      Previous: "Previous",
      SkipIntro: "Skip intro",
      FirstVisitCheckInfoLastStepButton: "Save & Start to use DeliwiAI",
      Continue: "Continue",
      PleaseCheckYourInformationBelow: "Please take a moment to check your personal information below and make any necessary corrections.",
      LinkedInPermissionAllow: "I give DeliwiAI permission to import my public LinkedIn profile data",
      LinkedInPermissionNoLinkedin: "I do not have a LinkedIn",
      LinkedInPermissionDisallow: "I do not give permission to import my public data",
      LinkedInPermissionDisallowOrNoProfile: "I do not have a LinkedIn or I do not give permission to import my public data",
      YourLinkedInAddress: "Your public LinkedIn address*",
      CopyPasteLinkedIn: "Copy & paste your public LinkedIn address here",
      SaveAndStartToUse: "Save & Start to use DeliwiAi",
      LabelLinkedInPermissions: "Why and what is my public LinkedIn profile data",
      LabelPrivacyPolicy: "Permissions / Approving privacy & policy",
      DescriptionPrivacyPolicy: "<p>By providing this information and hence giving my explicit consent to process my personal data, I affirm the following:</p><ul><li>I have read and understood <a href='https://www.deliwi.ai/processing-personal-data' target='_blank' rel='noreferrer'>DeliwiAI's Description of the Processing of Personal Data</a> and I willingly agree to the processing of my personal data as described herein. I understand that the processing may involve profiling, which refers to the automated processing of personal data to evaluate or analyze certain aspects related to me and/or my work performance, and/ or automated decision-making.</li><li>I acknowledge that this processing activity is undertaken for objectives set forth by my employer/organization, and that pertinent information regarding this processing has been made available to me.</li><li>I acknowledge that this processing activity is undertaken for objectives set forth by my employer/organization, and that pertinent information regarding this processing has been made available to me.</li>",
      EnrichProfileWithLinkedIn: "Final step: Connect your LinkedIn for an instant profile boost – it imports key details, saving you loads of effort. Trust us, it's a real game changer!",
      SeeFullTalentCard: "Explore the full TalentCard",
      SearchInstructionsHeading: "You can ask for example:",
      SearchInstructions1Label: "Quick advice?",
      SearchInstructions1Content: "Write for example:\n ”I need help which subcontractor contract model to use in new markets?”",
      SearchInstructions2Label: "Resource queries?",
      SearchInstructions2Content: "Write for example:\n ”I need a resource for the project in Finland who can program interfaces to our ERP system and has experience in HR”",
      SearchInstructions3Label: "Who has experience?",
      SearchInstructions3Content: "Write for example:\n ”Does anyone have experience in retail cash register systems deployment in collaboration with the Techqurus Inc in Spain?”",
      SearchInstructions4Label: "Complex resource queries?",
      SearchInstructions4Content: "Write for example:\n ”I need an mechanical engineer with vast expertise on our moulding machinery. This person must speak Hindi and be used to working in disaster recovery zones and also have experience in team leadership.”",
      SearchLoadingText1: "Searching talents from your organization...",
      SearchLoadingText2: "I will do this carefully, so please be patient.",
      NoResultsLabel: "Couldn't find any matches for your search.",
      NoResultsText1: "Maybe you don’t have this kind of talent in your organization or maybe the search contained too many search elements. ",
      NoResultsText2: "Please consider reforming your search criteria or reaching out to our support team for assistance.",
      TalentCard: "Talent card",
      Setup: "Setup",
      InvalidLinkedInURL: "Invalid URL. This doesn't seem to be a proper LinkedIn profile URL.",
      FirstSteps: "First steps",
      EnableChatbot: "Enable Chatbot for Microsoft Teams",
      EnableChatbotWhy: "Chatbot for Teams acts as a communication tool between the DeliwiAI and the user.",
      TalentCardPreviewLabel: "Talent card preview",
      FeatureUnderConstruction: "This feature is under construction",
      AddNewProfilePicture: "Add new profile photo",
      ProfilePictureEditLabel: "Edit your profile photo",
      EditSkillsStep1Label: "STEP 1: Add new skill",
      EditSkillsStep2Label: "STEP 2: Rate your skills",
      EditSkillsLevel0Description: "Drag and drop the skill into the skill category box e.g. “Professional skills”",
      FindSkillFromTheList: "Find skill from the list",
      SuggestedSkills: "Suggested skills",
      NoSkillsFound: "No skills found",
      SuggestedInterests: "Suggested interests",
      NoInterestsFound: "No interests found",
      GoToTalentCard: "Go to talent card",
      GoToControlPanel: "Go to Control panel",
      AddUsers: "Add users",
      ShowUsers: "Show users",
      NoUsers: "No users",
      SelectNewPhoto: "Select new photo",
      WelcomeAddUsers1: "As an admin you add and invite users in DeliwiAI.",
      WelcomeAddUsers2: "By adding users, you make the search results richer and help the user to find more answers.",
      ManageUsers: "Manage users",
      WelcomeManageUsers1: "In user management you can edit or delete users.",
      WelcomeManageUsers2: "Send activation reminders to users, set access rights or influence the user's visibility in the AI search results etc.",
      CreateAnalysis: "Create analysis",
      WelcomeCreateAnalysis1: "The analyzes help you understand what skills your organization has.",
      WelcomeCreateAnalysis2: "You can collect an analysis of existing Deliwi user data and you can create a inquiry for users if there is no data available – and, based on this, also enrich the data within the DeliwiAI.",
      WelcomeControlPanel: "Welcome to control panel",
      WelcomeAdminIntro: "As an admin, you can add and invite users to DeliwiAI, you manage user accounts and you can examine your organization's expertise on the analysis tab.",
      WelcomeMyProfile: "Welcome to My profile",
      WelcomeMyProfileIntro: "With the help of my profile information, you become visible to the rest of the organization.",
      ClearSearch: "Reset search",
      ChangeAccessRights: "Change User Access Rights",
      SendEmail: "Send Email",
      SendEmails: "Send Emails",
      Visible: "Visible",
      Hidden: "Hidden",
      ConfirmDeletion: "Confirm Deletion",
      MakeSureDeletionSingle: "Are you sure you want to delete this user?",
      MakeSureDeletionMulti: "Are you sure you want to delete these users?",
      Delete: "Delete",
      DeleteUser: "Delete User",
      DeleteUsers: "Delete Users",
      EditUsers: "Edit Users",
      Users: "Users",
      Show: "Show",
      AddSelectedUsers: "Add selected users to DeliwiAI",
      LoadingDots: "Loading...",
      Loading: "Loading",
      SearchListByNameOrEmail: "Search users from the list (by name or email)",
      ManageUserAccounts: "Manage user accounts",
      SuggestedPeople: "Suggested People",
      SuggestedContacts: "Suggested Contacts",
      NoResultsFound: "No results found",
      PeoplePickerSuggestionsAvailable: "People Picker Suggestions available",
      Remove: "Remove",
      SelectedContacts: "Selected contacts",
      TypeNameOrEmailToSearchUsers: "Type name or email to search for users",
      SelectUsers: "Select users",
      SendingInvitations: "Sending invitations",
      Success: "Success",
      CheckTheUserList: "Check the user list",
      SelectUserGroupsOrPeople: "Select user groups or people",
      AddingUsers: "Adding users & sending invitations...",
      UsersAdded: "Users added and invitations sent successfully.",
      UsersAddedAndEmailsSent: "Users have been added to the DeliwiAI and invitations have been sent.",
      ManageAccountsInList: "Manage user accounts in the user list.",
      UsersAreNowListed: "Users are now listed in the user list. You can see from the list when the user has activated i.e. logged in the DeliwiAI.",
      AddedUserLevelInfo: 'All added users have user-level access rights by default - notice that you can manage users in the "manage user accounts" section. Click “check the user list” and you will enter into “manage user accounts”.',
      AddUsersToDeliwi: "Add users to DeliwiAI",
      HowAndWhyAddPoepleToDeliwi: "How and why to add people to DeliwiAI?",
      AddNewUsersInfo1: "Add new members from “Add users”. When you have added users to the DeliwiAI, the users will be listed in the “Manage user accounts” section.",
      AddNewUsersInfo2: "By adding users, you make the search results richer and help the user to find more answers.",
      // AddNewUsersInfo3: "When you add users to the service, the service automatically sends invitations to users via Chatbot for Microsoft Teams and email. You can check and edit invitation messages at any time you want - in advance or when you add users to the service.",
      AddNewUsersInfo3: "When you add users to the service, the service automatically sends invitations to users via Chatbot for Microsoft Teams and email.",
      EditUserOrUsers: "Edit User(s)",
      Close: "Close",
      Activated: "Activated",
      PendingActivation: "Pending Activation",
      UserName: "User Name",
      Department: "Department",
      ProfileFillRate: "Profile Fill Rate",
      Visibility: "Visibility",
      AccessRight: "Access Right",
      ProfileStatus: "Profile status",
      ToggleSelection: "Toggle selection",
      ToggleSelectionAllTtems: "Toggle selection for all items",
      selectRow: "select Row",
      ChooseSkillAndRunAnalysis: "Choose skill and run analysis",
      SearchVisibility: "Search Visibility",
      WhatIsAnalysisTitle: "What is this? What can i do in analysis?",
      StudyTheOrganization: "Study the organization",
      AnalysisInfoCol1Title: "Analyze existing data",
      AnalysisInfoCol1Text1: "As an Admin or SuperUser, you can analyze existing DeliwiAI user data and study what skill levels are there in your organization.",
      AnalysisInfoCol1Text2: "The DeliwiAI only analyzes activated DeliwiAI users.",
      AnalysisInfoCol1Text3: "In My profile, members can add all their skills into DeliwiAI. This is the data you can analyze directly from the DeliwiAI platform.",
      AnalysisInfoCol2Title: "Run a survey",
      AnalysisInfoCol2Text1: "Analysis returns you the resulting analysis gathered from the existing data. Sometimes the result can be that a certain skill cannot be found on the users' skill cards.",
      AnalysisInfoCol2Text2: "If the analysis returns the so-called zero result or only a few people know a certain skill - it is recommended to <strong>run an inquiry</strong> for users.",
      AnalysisInfoCol2Text3: "Inquiry not only produces a survey, but <strong>enriches people's talent cards</strong> as a result of the inquiry.",
      AnalysisInfoTop: "It is recommended that you first analyze existing data that may already be found in the DeliwiAI. If the analysis produces few results or a zero result, I recommend running an inquiry after the analysis.",
      AnalysisInfoBottom: "However, don't forget that running an inquiry is not prohibited even if you don't run an analysis beforehand.",
      RunAnalysis: "Run analysis",
      CreateSurvey: "Create survey",
      Reports: "Reports",
      AllUsers: "All users",
      DepartmentOrUsers: "Department or specific users",
      AnalysisExplanation: "Analysis = runs an one time analysis of existing DeliwiAI user skilldata and creates a report of it. ",
      SelectTheUsersToAnalyze: "Select the users whose skills you want to analyze",
      MyInterestsAndHobbies: "My interests & hobbies",
      AddInterestLabel: "Add keywords to your interests to enrich the overall picture (optional but highly recommended)",
      MyInterests: "My interests",
      MyInterestsShortDescriptionLabel: "Short description of my interests and hobbies",
      ShortDescriptionOfInterestsEditLabel: "Short description about your interests/hobbies",
      WorkHistory: "Work history",
      AddWork: "Add work",
      SelectTheUsersForSurvey: "Select the users for the survey",
      InquiryExplanation: "Inquiry = Makes an inquiry to users via Chatbot for Teams, creates a report and saves it. ",
      ChooseTheSkillToAnalyze: "Choose the skill you want to analyze",
      TeamsSettings: "Microsoft Teams settings",
      WhatIsThis: "What is this?",
      ChatbotExplanation: "Chatbot for Teams acts as a communication tool between the DeliwiAI and the user. It is highly recommended to keep this feature enabled.",
      ChatbotOnOff: "Chatbot for Microsoft Teams function on / off",
      ChatbotForMicrosoftTeams: "Chatbot for Microsoft Teams",
      CantCommunicate: "Note that the DeliwiAI can not communicate with you via Teams as long as Chatbot for Microsoft Teams is set to off.",
      TurningFunctionalityOff: "You are turning the functionality off",
      TurningFunctionalityOffMeans: "Turning off this functionality means that the DeliwiAI cannot communicate via Chatbot for Microsoft Teams",
      YesImSure: "Yes, I'm sure",
      Teams: "Teams",
      Email: "Email",
      Call: "Call",
      Dismiss: "Dismiss",
      AreYouSure: "Are you sure?",
      HelpfulInformation: "Helpful Information",
      ShowExplanation: "Show explanation",
      DragSkillsHere: "Drag skills here",
      ShortDescriptionDots: "short description ...",
      DeleteDialogTitle: "Delete {{itemType}}",
      DeleteDialogText: "Are you sure you want to remove [{{itemName}}] from your {{itemType}}?",
      ConfirmRemoveTitle: "Are you sure?",
      AddShortDescription: "Add short description",
      MyCareer: "My career",
      CurrentlyWorkingInThisPosition: "I am currently working in this position",
      CurrentlyWorkingInThisProject: "I am currently working in this project",
      AddNewPosition: "Add new position",
      ShortCareerSummary: "Short career summary",
      AddExperience: "Add experience",
      ShortCareerDescription: "Short career description",
      TrainingsAndCourses: "Trainings & courses",
      AddTrainingOrCourse: "Add new training or course",
      AddInterestsAndHobbies: "Add interests and hobbies",
      CreateDeliwiAIUserGroupsEntra: "Create DeliwiAI user groups in Entra ID",
      CreateGroups: "Create user groups",
      AwardingBody: "Awarding body",
      AwardGranted: "Award granted",
      AwardName: "Award name",
      CreateDeliwiAIUserGroupsWhy: "These security groups are used for authorizing requests to DeliwiAI backend. Please follow your organizations naming convention when creating new security groups.",
      DefineNamesEntraSecurityGroups: "Please provide names for Entra ID security groups",
      AddAdminUsersDeliwiAi: "Add admin users to DeliwiAI",
      AddAdminUsersDeliwiAiWhy: "Implementation also requires adding admin users to the service. The service must have at least one admin user who can manage users.",
      SelectAdminUserOrUsers: "Select admin user/users",
      Why: "Why?",
      TheVeryFirstThingToDo: "the very first thing to do is to enable the Chatbot for Microsoft Teams, create DeliwiAI user groups in Entra ID and invite admin users to the DeliwiAI.",
      WelcomeToDeliwiAI: "Welcome to DeliwiAI,",
      WhyDeliwiAI: "Chatbot for Teams acts as a communication tool between the DeliwiAI and the users. In order to send user invites to deliwiAI you need to enable Teams bot for your organization.",
      AddSelectedUsersAsAdmins: "Add selected users as Admins to DeliwiAI",
      TypeUserNameToSearchUsers: "Type user name to search for users...",
      SetupCompleted: "Setup completed",
      CloseTheSetup: "Close the setup",
      InvitationMessage: "Invitation message",
      DeleteUserFromDeliwiAI: "Delete user from DeliwiAI",
      DeleteUsersFromDeliwiAI: "Delete users from DeliwiAI",
      WhenDeletingUserAllInformationLost: "When deleting a user, all the user's information from the service will be lost.",
      WhenDeletingUsersAllInformationLost: "When deleting these users, all the users' information from the service will be lost.",
      SetupHasBeenCompletedSuccessfully: "Admin users have been added to the DeliwiAI and invitations have been sent. ",
      AdminUsersHaveBeenAddedAndInvitationsSent: "Setup has been completed successfully ",
      CertificationDate: "Certification date",
      Awarded: "Awarded",
      OrganizerOrTrainer: "Organizer / Trainer",
      CompletedDate: "Completed",
      StartDate: "Start date",
      EndDate: "End date",
      EndDateOrExpected: "End date (or expected)",
      QuickTour: "Quick tour",
      InvestigateYourOrganization: "Investigate your organization",
      AnalyzeTitle: "Analyze title",
      RunningAnalysis: "Running Analysis...",
      RunNewAnalysis: "Run new analysis",
      AddSkills: "Add skills",
      RateSkills: "Rate skills",
      RateLanguageSkills: "Rate language skills",
      TypeDepartmentNameToAnalyze: "Type department name you want to analyze",
      Admin: {
        Tab: {
          UserManagement: "User management",
          SkillAnalysis: "Skill analysis",
          MessageTemplates: "Message templates",
          CompanyInfo: "Company info",
          TeamsSettings: "Teams settings",
        },
      },
      Tour: {
        QuickTour: {
          Title: "How DeliwiAI works?",
          Content: "<p>The DeliwiAI finds the people you need – whether you need help, a resource for a project or sparring. The DeliwiAI finds a competent person.</p><p>Write in your own words what kind of advice, insights or skilled resources you are looking for.</p><p>You can search for:</p><ul><li>Specific skill</li><li>Person who could answer to your question </li><li>Specialist for your project</li></ul><p class='tour-subtitle'>Try to find someone with specific skill?</p><p>Just tell to DeliwiAI what skill you are looking for.</p><p class='tour-subtitle'>Try to find someone who has answer to your question or someone to spar with?</p><p>Just tell to DeliwiAI what kind of question or conversation topic do you have in mind.</p><p>DeliwiAI will get the best matches as a response.</p>",
        },
      },
      Help: {
        EnableChatbot: {
          Title: "Why Enable Chatbot for Teams?",
          Content: "<p><strong>Chatbot for Teams</strong> acts as a communication tool between the DeliwiAI and the users.</p><p><strong>Enabling</strong> is necessary in order to send users invitations via Chatbot for Teams.</p>",
        },
        EntraID: {
          Title: "What this means?",
          Content: "<p>This operation creates new security groups with the given names in your Entra ID. You can also provide object ids of existing security groups.</p><p>Please follow your organizations naming convention when creating new security groups.</p>",
        },
        AddAdmins: {
          Title: "How to add Admins?",
          Content: "<p>Start typing a user name to the text field. While you write something the list will suggest a username for you. </p><p>Choose members from the list and you will see that members will be added inside the field.</p><p>You can delete your pick from “cross-icon” if you accidentally chose the wrong person.</p><p>Remember to click <strong>“Add selected users as Admins to DeliwiAI”</strong> in order to add users into application.</p>",
        },
        AddUsers: {
          Title: "How do I search for people I want to add to DeliwiAI?",
          Content: "<p>Start typing a user OR group name to text field. While you write something a list of matching members will appear below the field. Choose members from the list and you will see that members will be added inside the field.</p><p>You can delete your pick from “cross-icon” if you accidentally chose the wrong person or group.</p><p>Remember to click “Add and continue” in order to add users into application.</p><p><strong>What if I add a whole group?</strong></p><p>If you add a group you will add all the members from that group.</p>",
        },
        InvitationMessages: {
          Title: "Adding users & sending invitations",
          Content: "<p>Invitation messages are sent to users when inviting them to DeliwiAI.</p><p>NOTE: System automatically sends a default instructions for the user to log in.</p>",
        },
        InvitationMessagesPhase2: {
          Title: "Adding users & sending invitations",
          Content: "<p>Invitation messages are sent to users when inviting them to DeliwiAI. You can edit the welcome message in advance or when you add users to the service.</p><p>NOTE: You are not required to alter the message as the system automatically sends a default instruction for the user to log in.</p>",
        },
        EditUsers: {
          Title: "How the user rights differ from each other?",
          Content: "<p><strong>User</strong> = The user can do user searches with AI-search and update own talent card. The user can only see limited information from the Talent card about another person.</p><p><strong>SuperUser</strong> = The superuser can see all the information on the talent cards and this user can also perform user analyses.</p><p><strong>Admin</strong> = “All rights” - Commissioning, user management, management and execution of analysis studies.</p>",
        },
        SearchVisibility: {
          Title: "What is user visibility?",
          Content: "<p>This means that the user is prevented from appearing in DeliwiAI search results.</p><p>If the user's appearance in the DeliwiAI search results is blocked, other users will not be able to find the user through DeliwiAI search.</p>",
        },
        MyProfile: {
          FutureDevelopment: {
            WorkloadPrediction: {
              Title: "Workload prediction",
              Content: "<p>Workload percentage indicates the amount of assigned or completed work relative to total capacity.</p><p>A higher percentage means a larger workload, while a lower percentage suggests a lighter workload.</p><p>High % = high workload<br>Low %  = low workload</p>",
            },
          },
        },
        LinkedIn: {
          Title: "Why and what is my public Linkedin profile data",
          Content: "<p>Your public LinkedIn information will make your DeliwiAI profile shine!</p><p>We import your work history, educational background, certifications, awards, and skills and language information from LinkedIn ready for you to your DeliwiAI “My Profile” from your public LinkedIn profile.</p>",
        },
        EditForms: {
          FutureDevelopment: {
            WorkloadPrediction: {
              Title: "Workload prediction",
              Content: "Workload percentage indicates the amount of assigned or completed work relative to total capacity. A higher percentage means a larger workload, while a lower percentage suggests a lighter workload.",
            },
          },
          EditSkills: {
            AddNewSkill: {
              Title: "How to add a new skill?",
              Content: "<p>Start typing a skill name to the text field. Skill list will appear below the field. Choose skill from the list and click “Add”.</p><p>If you can't find the skill you need in the list, write the skill in the text field and click “Add” </p><p>Skill will appear below STEP2 where you can rate the skill.</p>",
            },
            RateSkills: {
              Title: "Rate your skill",
              Content: "<p>Drag and drop skill under the right category: Special expertise, Professional OR Basic.\n</p><p>Notice, that you can also edit a previously rated skill by dragging the skill under another skill level category.</p><p>Remember to save changes.</p>",
            },
          },
          EditLanguageSkills: {
            AddNewLanguage: {
              Title: "How to add a new language?",
              Content: "<p>Start typing a language to the text field. Language list will appear below the field. Choose language from the list and click “Add”.</p><p>Language will appear below STEP2 where you can rate it.</p>",
            },
            RateLanguages: {
              Title: "Rate your language",
              Content: "<p>Drag and drop language under the right category: Native or bilingual, Professional OR Basics.\n</p><p>Notice, that you can also edit a previously rated languages by dragging the language under another language category box.</p><p>Remember to save changes.</p>",
            },
          },
        },
      },
      EditLanguageSkills: {
        SuggestedLanguages: "Suggested languages",
        NoLanguagesFound: "No languages found",
        Step1Label: "STEP 1: Add new language",
        LanguagePickerLabel: "Find language from the list",
        Step2Label: "STEP 2: Rate your language",
        DragAndDropDescriptionText: "Drag and drop the language into the language category box e.g. “Professional”",
      },
      FirstVisit: {
        Step1: {
          Title: "Welcome to the DeliwiAI",
          Content: "If there's a skill or resource you need, it's likely someone in your organization has it. With DeliwiAI, you can <span>find</span> it easily and quickly.",
        },
        Step2: {
          Title: "A single search with your natural language",
          Content: "Write in your own words what kind of advice, insights or skilled resources you are looking for.",
        },
        Step3: {
          Title: "Get the best matches as a response",
          Content: "DeliwiAI will find and match the right people from your organization for your specific needs.",
        },
        Step4: {
          Title: "Get to know the person and contact",
          Content: "DeliwiAI will find and match the right people from your organization for your specific needs.",
        },
      },
      UserProfile: {
        Empty: {
          Skills: {
            Professional: "Do you possess special expertise—a unique and advanced skill set or knowledge in a specific area that sets you apart in your field?",
            Expert: "Do you have professional expertise—a specialized and advanced skill set or knowledge in your field that distinguishes you professionally?",
            Basic: "Do you possess basic skills essential for your field, ensuring a strong foundation in your professional endeavors or just happen to know a little a of everything?",
          },
          Interests: {
            Title: "What interests or hobbies do you have?",
            Content: "What are your hobbies or what gets you excited? Don’t hesitate to showcase them. You might get new friends from your colleagues!",
            Button: "Add short description",
          },
          InterestsKeywords: {
            Title: "Showcase your hobbies with keywords",
            Content: "Keywords or short phrases help others quickly grasp your interests at a glance.",
            Button: "Add keywords",
          },
          WorkExperience: {
            Title: "Here you can add your work experience",
            Content: "Make your work history visible by listing recent employers where you have worked.",
            Button: "Add experience",
          },
          Projects: {
            Title: "Make your latest projects visible",
            Content: "What types of projects have you been working on recently?",
            Button: "Add project",
          },
          Career: {
            Title: "Add a short summary about your career",
            Content: "Add a short summary of your career, including your previous roles and main responsibilities. ",
            Button: "Add short description",
          },
          CurrentWork: {
            Title: "Add your job description",
            Content: "Add a short description of your current job, including your role and main responsibilities.",
            Button: "Add short description",
          },
          PersonalInformation: {
            Title: "You have unfilled information in your personal information",
            Button: "Fill my information",
          },
          CompanyDetails: {
            Title: "You have unfilled company details",
            Button: "Fill company details",
          },
          threeYears: {
            Title: "What or where you are aiming for in 3 years?",
            Content: "In the next three years, what are your career goals or aspirations? Feel free to share your vision briefly!",
            Button: "Fill future & development info",
          },
          fiveYears: {
            Title: "Can you imagine your dream job 5 years from now?",
            Content: "Can you picture your ideal job five years from now? Write a brief description or share the type of position you would like to work in.",
            Button: "Fill future & development info",
          },
          Awards: {
            Title: "Show of with your awards",
            Content: "Workplace awards, or awards in a professional context, refer to recognitions and honors received for exceptional performance, achievements, or contributions in the workplace.",
            Button: "Add award",
          },
          Education: {
            Title: "Here you can list your educational background",
            Content: "Embark on a journey through your educational timeline! List and share the schools you've attended and when.",
            Button: "Add education",
          },
          Training: {
            Title: "Have you participated in training or course that helps you in your work?",
            Content: "Courses and training give substance to your skills and education, completing the full spectrum with a touch of expertise. You can add all the courses and training sessions you find beneficial for your job.",
            Button: "Add training or course",
          },
          Certificates: {
            Title: "Do you have certifications?",
            Content: "A certificate is a formal document confirming the completion of a course or training, indicating specific skills or knowledge acquired.",
            Button: "Add certification",
          },
        },
      },
      Nav: {
        MyProfile: "My profile",
        AISearch: "AI search",
        ControlPanel: "Control Panel",
        Control: "Control",
        Panel: "panel",
        LogOut: "Log out",
      },
      BecomeVisible: "Become visible",
      BecomeVisible1: "My profile details help you to become visible to the rest of the organization.",
      BecomeVisible2: "In My profile, you can add all your personal information, current details about your position, work history, skills, etc.",
      ShowYourTalent: "Show your talent",
      ShowYourTalent1: "Even the smallest of your skills will not go unnoticed.",
      ShowYourTalent2: "Your My profile data enrichment is highly recommended to make your skills visible and to help other employees find you through DeliwiAI search whenever they need an expert like you.",
      HowItWorks: "How it works?",
      HowItWorks1: "Simply, click edit and fill missing data or make corrections.",
      HowItWorks2: "DeliwiAI recommend to enrich your data right away. But if you decide to do it later, DeliwiAI remind you from time to time to fill in the information.",
      GoToMyProfile: "Go to my profile",
      TalentCardCopiedMessage: "Talent card link copied to clipboard",
      ReadMore: "Read more",
      ReadLess: "Read less",
      LetsSeeHowItWorks: "Let's see how it works",
      Welcome: "Welcome!",
      NotGiven: "Not given",
      FillMyInformation: "Fill my information",
      StartToUseApp: "Start to Use App",
      SaveContinue: "Save & continue",
      ConnectWithLinkedIn: "Connect with LinkedIn",
      SchoolName: "School name",
      ToFindPublicProfileURL: "To find your public profile URL:",
      FillRateTitles: {
        Level1: "Blurry",
        Level2: "Beginner",
        Level3: "Rising Star",
        Level4: "Advanced",
        Level5: "Pro Stellar",
      },
    },
  },
};

i18next.use(initReactI18next).init({ resources, lng: "en", interpolation: { escapeValue: false } });

export default i18next;
