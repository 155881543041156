import { t } from "i18next";

import { ControlPanelWelcome } from "components/ControlPanelWelcome";
import { ControlPanelTabs } from "components/ControlPanel/ControlPanelTabs";

import "assets/styles/pages/ControlPanel.scss";

export const ControlPanel = () => {
  return (
    <div className="admin admin-page">
      <ControlPanelWelcome />
      <div className="admin-page__container">
        <h1 className="text-style--Large-title text-style--semibold">{t("ControlPanel")}</h1>
        <ControlPanelTabs />
      </div>
    </div>
  );
};
