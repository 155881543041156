import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconCall = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25368 8.62424L7.75995 7.2426C8.60377 6.46864 8.89317 5.25993 8.49147 4.18772L7.84783 2.46963C7.34918 1.13859 5.9011 0.425147 4.54186 0.840847C2.13196 1.57787 0.27982 3.81726 0.85003 6.47766C1.22502 8.22722 1.94195 10.4233 3.30181 12.7609C4.66398 15.1025 6.21962 16.8337 7.55315 18.0477C9.56737 19.8815 12.4463 19.4229 14.2985 17.696C15.3289 16.7353 15.4341 15.1388 14.5387 14.0512L13.3584 12.6174C12.6307 11.7334 11.4392 11.3797 10.347 11.7235L8.39737 12.3371C8.32237 12.2601 8.23547 12.1679 8.14077 12.0623C7.82041 11.7051 7.41971 11.2046 7.08271 10.6209C6.74571 10.0372 6.51255 9.43987 6.36341 8.98386C6.3193 8.849 6.28293 8.72771 6.25368 8.62424Z" fill="#020404" />
      </svg>
    </span>
  );
};
