import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";

import { I18nextProvider } from "react-i18next";
import localization from "localization";

import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "msalConfig";

import { PageLayout } from "components/PageLayout";
import { Search } from "pages/Search";
import { MyProfile } from "pages/MyProfile";
import { ControlPanel } from "pages/ControlPanel";
import { Setup } from "pages/Setup";
import { UserProfile } from "pages/UserProfile";
import { FirstVisitPopup } from "components/FirstVisitPopup";
import { UserRoleProvider } from "contexts/UserRoleContext";
import { sendUserLoginTime } from "services/apiService";

import "assets/styles/app.scss";

interface AppProps {
  instance: PublicClientApplication;
}

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Search />} />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/user-profile/:id" element={<UserProfile />} />
      <Route path="/control-panel" element={<ControlPanel />} />
      <Route path="/setup" element={<Setup />} />
    </Routes>
  );
};

const authRequest = {
  ...loginRequest,
};

/**
 * All parts of the application that require authentication must be wrapped in the MsalProvider component.
 * The MsalAuthenticationTemplate component will render its children if a user is authenticated or attempt to sign a user in.
 */
const App = ({ instance }: AppProps) => {
  useEffect(() => {
    instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        sendUserLoginTime();
      }
    });
  }, [instance]);

  return (
    <Router>
      <MsalProvider instance={instance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
          <UserRoleProvider>
            <I18nextProvider i18n={localization}>
              <PageLayout>
                <FirstVisitPopup />
                <Pages />
              </PageLayout>
            </I18nextProvider>
          </UserRoleProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Router>
  );
};

export default App;
