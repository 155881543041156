import { useRef, useState, useEffect, useCallback, useImperativeHandle, forwardRef, FocusEvent } from "react";

import { t } from "i18next";

import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { IBasePicker, IBasePickerSuggestionsProps, NormalPeoplePicker, PeoplePickerItem, ValidationState, IPickerItemProps } from "@fluentui/react/lib/Pickers";
import { searchUsersGroups, getInitialSetupUsers, getAnalysisUsers } from "services/apiService";

interface PeoplePickerProps {
  onUserSelect: (selectedUsers: IPersonaProps[]) => void;
  pickerType?: string;
}

export interface PeoplePickerRef {
  clearSelection: () => void;
}

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: t("SuggestedPeople"),
  mostRecentlyUsedHeaderText: t("SuggestedContacts"),
  noResultsFoundText: t("NoResultsFound"),
  loadingText: t("Loading"),
  showRemoveButtons: true,
  suggestionsAvailableAlertText: t("PeoplePickerSuggestionsAvailable"),
  suggestionsContainerAriaLabel: t("SuggestedContacts"),
};

export const PeoplePicker = forwardRef(({ onUserSelect, pickerType = "users" }: PeoplePickerProps, ref) => {
  const [selectedItems, setSelectedItems] = useState<IPersonaProps[]>([]);
  const picker = useRef<IBasePicker<IPersonaProps>>(null);
  const [pickerLabel, setPickerLabel] = useState<string>(t("TypeNameOrEmailToSearchUsers"));

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      setSelectedItems([]);
    },
  }));

  useEffect(() => {
    if (pickerType === "setup") {
      setPickerLabel(t("TypeUserNameToSearchUsers"));
    } else if (pickerType === "department") {
      setPickerLabel(t("TypeDepartmentNameToAnalyze"));
    } else {
      setPickerLabel(t("TypeNameOrEmailToSearchUsers"));
    }
  }, []);

  const removeDuplicates = useCallback((personas: IPersonaProps[], possibleDupes: IPersonaProps[]) => {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
  }, []);

  const listContainsPersona = useCallback((persona: IPersonaProps, personas: IPersonaProps[]) => {
    return personas.some((item) => item.text === persona.text);
  }, []);

  const onChange = useCallback(
    (items?: IPersonaProps[]) => {
      if (items) {
        setSelectedItems(items);
        onUserSelect(items);
      }
    },
    [onUserSelect],
  );

  const onFilterChanged = useCallback(
    async (filterText: string, currentPersonas?: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
      if (!filterText || filterText.length < 3) {
        return [];
      }

      let response;
      try {
        if (pickerType === "setup") {
          response = await getInitialSetupUsers(filterText);
        } else if (pickerType === "analysisusers") {
          const analysisResponse = await getAnalysisUsers(0, 50, filterText);

          if (!analysisResponse.Data) {
            console.error("Invalid response structure for analysis users:", analysisResponse);
            return [];
          }
          // console.log(analysisResponse);
          response = {
            Results: analysisResponse.Data.map((user) => ({
              DisplayName: user.FirstName || user.LastName ? `${user.FirstName} ${user.LastName}` : user.Email,
              Id: user.Id,
              Type: user.JobTitle, // Assuming 'Type' corresponds to 'JobTitle'
            })),
          };
        } else {
          response = await searchUsersGroups(filterText);
        }

        if (!response.Results) {
          console.error("Invalid response structure:", response);
          return [];
        } else {
          let filteredPersonas = response.Results.map((item) => ({
            text: item.DisplayName || "", // Provide a fallback empty string
            key: item.Id,
            secondaryText: item.Type,
          })).filter((item): item is { text: string; key: string; secondaryText: string } => item.text !== undefined); // This ensures text is defined

          // Explicit type assertion here to satisfy TypeScript's type system
          filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas || []) as { text: string; key: string; secondaryText: string }[];

          filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;

          return filteredPersonas;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    },
    [removeDuplicates, pickerType],
  );

  const renderItemWithSecondaryText = (props: IPickerItemProps<IPersonaProps>) => {
    const newProps = {
      ...props,
      item: {
        ...props.item,
        ValidationState: ValidationState.valid,
        showSecondaryText: false,
      },
    };

    return <PeoplePickerItem {...newProps} />;
  };

  return (
    <div>
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={"ms-PeoplePicker"}
        key={"normal"}
        onRenderItem={renderItemWithSecondaryText}
        onValidateInput={validateInput}
        selectionAriaLabel={t("SelectedContacts")}
        removeButtonAriaLabel={t("Remove")}
        inputProps={{
          "aria-label": "People Picker",
          placeholder: pickerLabel,
        }}
        componentRef={picker}
        onInputChange={onInputChange}
        resolveDelay={300}
        selectedItems={selectedItems}
        onChange={onChange}
      />
    </div>
  );
});

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf("@") !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input: string): string {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}
