import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconStarUser = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.23529 0C5.63631 0 3.52941 2.10689 3.52941 4.70588C3.52941 7.30487 5.63631 9.41177 8.23529 9.41177C10.8343 9.41177 12.9412 7.30487 12.9412 4.70588C12.9412 2.10689 10.8343 0 8.23529 0ZM2.36321 10.5882C1.06253 10.5882 0 11.6316 0 12.9412C0 14.9308 0.979776 16.4309 2.51175 17.4078C4.01996 18.3694 6.05325 18.8235 8.23529 18.8235C8.71865 18.8235 9.19471 18.8013 9.65938 18.7561C8.76842 17.6474 8.23529 16.2389 8.23529 14.7059C8.23529 13.1416 8.79038 11.707 9.71431 10.5882H2.36321ZM20 14.7059C20 17.6298 17.6298 20 14.7059 20C11.782 20 9.41177 17.6298 9.41177 14.7059C9.41177 11.782 11.782 9.41177 14.7059 9.41177C17.6298 9.41177 20 11.782 20 14.7059ZM15.2659 11.6006C15.0896 11.0351 14.3221 11.0351 14.1459 11.6006L13.5841 13.4027H11.7665C11.196 13.4027 10.9588 14.1636 11.4203 14.5132L12.8908 15.6269L12.3292 17.4289C12.1529 17.9945 12.7739 18.4648 13.2353 18.1153L14.7059 17.0015L16.1765 18.1153C16.6379 18.4648 17.2588 17.9945 17.0826 17.4289L16.5209 15.6269L17.9914 14.5132C18.4529 14.1636 18.2158 13.4027 17.6453 13.4027H15.8276L15.2659 11.6006Z" fill="#08757A" />
      </svg>
    </span>
  );
};
