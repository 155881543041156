import { IUserProfile } from "../../interfaces/IUserProfile";
import { t } from "i18next";
import "assets/styles/components/ProfilePreviewOverview.scss";
import { IconMail } from "../Icons/IconMail";
import { IconLocation } from "../Icons/IconLocation";
import { Link } from "react-router-dom";
import { DeliwiButton } from "components/DeliwiButton";
import { IconArrowForward } from "../Icons/IconArrowForward";
import { IconTeams } from "../Icons/IconTeams";
import { IconLink } from "../Icons/IconLink";
import { UserAvailability } from "./UserAvailability";
import { useUserRole } from "contexts/UserRoleContext";
import avatarSvg from "assets/images/avatar.svg";

export interface IUserProfileOverviewProps {
  userProfile: IUserProfile;
  userAvailability?: string;
}

export const UserProfilePreviewOverview = ({ userProfile, userAvailability }: IUserProfileOverviewProps) => {
  const firstName = userProfile?.FirstName || "";
  const lastName = userProfile?.LastName || "";
  const fullName = `${firstName} ${lastName}`;
  const jobTitle = userProfile?.JobTitle || "";
  const city = userProfile?.Location?.City || userProfile?.Company?.Location?.City || "";
  const country = userProfile?.Location?.Country || userProfile?.Company?.Location?.City || "";
  const userLocation = !!city ? city + ", " + country : country;
  const email = userProfile?.Email || "";
  const profilePictureUrl = userProfile?.ProfilePictureUrl || avatarSvg;
  const { userRole } = useUserRole();

  return (
    <div className="profile-preview-overview">
      <div className="profile-preview-overview--container">
        <div className="profile-preview-overview--content--island">
          <div className="profile-preview-overview--content--top">
            <div className="profile-preview-overview--avatar--container">
              <img className="profile-preview-overview--avatar--item" alt={fullName} src={profilePictureUrl} />
              {!!userAvailability && <UserAvailability availability={userAvailability} />}
            </div>
            <div className="profile-preview-overview--contact-info">
              <span className="text-style--Title3 text-style--semibold">{fullName}</span>
              <span className="text-style--Subtitle1 text-style--semibold">{jobTitle}</span>
            </div>
          </div>
          <div className="profile-preview-overview--content--bottom">
            <div className="profile--quick-links">
              <a className="profile--quick-links--link" target="_blank" rel="noreferrer" href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}>
                <IconTeams className="icon--teams" />
              </a>
              {userRole.includes("admin") || userRole.includes("superuser") ? (
                <span
                  className="profile--quick-links--link"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + "/user-profile/" + userProfile.id);
                    alert(t("TalentCardCopiedMessage"));
                  }}
                >
                  <IconLink className="" />
                </span>
              ) : userRole.includes("user") ? (
                <a className="profile--quick-links--link" href={`mailto:${email}`}>
                  <IconMail className="icon-size--20" />
                </a>
              ) : null}
            </div>
            <div className="location--wrapper">
              <IconLocation className="icon--location" />
              <span className="text-style--Body1 country">{userLocation}</span>
            </div>
          </div>
        </div>
        <div className="profile--see-profile-link">
          {(userRole.includes("admin") || userRole.includes("superuser")) && (
            <Link to={"/user-profile/" + userProfile.id} target="_blank">
              <DeliwiButton className="button-style--primary button-size--large mx-auto">
                <IconArrowForward />
                {t("SeeFullTalentCard")}
              </DeliwiButton>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
