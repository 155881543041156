import { t } from "i18next";

import IconTools from "assets/icons/icon-tools.svg";
import "assets/styles/pages/ControlPanel.scss";

export const TabMessageTemplates = () => {
  return (
    <>
      <div className="admin-box">
        <h2 className="text-style--Subtitle1 text-color--brand10 mb-32">{t("FeatureUnderConstruction")}</h2>
        <div className="admin-box__iconset mb-32">
          <img src={IconTools} className="icon-size-62 icon-shadow" alt="" />
          <div>
            <h3 className="text-style--Title3 text-style--semibold mb-16">"Message Templates" feature is on its way</h3>
            <p className="text-style--Body1">With this feature, you can customize email messages sent by this app.</p>
          </div>
        </div>
      </div>
    </>
  );
};
