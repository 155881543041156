import { useParams } from "react-router-dom";
import { getUserAvailability, getUserProfileByIdentifier } from "../services/apiService";
import { useEffect, useState } from "react";
import { IUserProfile } from "../interfaces/IUserProfile";
import { UserProfileOverview } from "../components/UserProfile/UserProfileOverview";
import { UserProfileDetails } from "../components/UserProfile/UserProfileDetails";
import { t } from "i18next";

const profileBackgroundImage = require("../assets/images/bg-profile-uncropped.jpg");

export const UserProfile = () => {
  const { id } = useParams();

  const [selectedUserProfile, setSelectedUserProfile] = useState<IUserProfile>();
  const [userAvailability, setUserAvailability] = useState<string>("Unknown");
  useEffect(() => {
    (async () => {
      if (id !== undefined) {
        const selectedUserProfile = await getUserProfileByIdentifier(id);
        setSelectedUserProfile(selectedUserProfile);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id !== undefined) {
        const userAvailability = await getUserAvailability(id);
        if (!!userAvailability?.availability) {
          setUserAvailability(userAvailability.availability);
        }
      }
    })();
  }, [id]);
  return (
    <>
      {selectedUserProfile && (
        <div className="user-profile-page">
          <div className="user-profile-page--background--container">
            <div className="user-profile-page--background--image" style={{ backgroundImage: `url(${profileBackgroundImage}` }}></div>
          </div>
          <div className="user-profile-page--container">
            <div className="user-profile-page--overview">
              <h1 className="text-style--Large-title">{t("TalentCard")}</h1>
              <UserProfileOverview userProfile={selectedUserProfile} displayMode="user-profile" userAvailability={userAvailability} />
            </div>
            <div className="user-profile-page--details">
              <UserProfileDetails userProfile={selectedUserProfile} displayMode="user-profile" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
