import { DeliwiButton } from "components/DeliwiButton";
// The icons are in use but the way IIconProps is setting them is causing this
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { ISkill, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { UserSkillsEditAddSkill } from "./UserSkillsEditAddSkill";
import { UserSkillsEditRateSkills } from "./UserSkillsEditRateSkills";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "assets/styles/components/EditSkills.scss";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IUserSkillsFormProps {
  Name: string;
  Level: number;
}

export const UserSkillsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [skills, setSkills] = useState<ISkill[]>([] as ISkill[]);
  const isInitialMount = useRef(true);

  const saveNewValues = () => {
    const sortedValues = skills.sort((a, b) => b.Level - a.Level);
    const updatedProfile = {
      ...shadowUserProfile,
      Skills: sortedValues,
    } as IUserProfile;

    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };

  useEffect(() => {
    if (isInitialMount.current) {
      setSkills(shadowUserProfile.Skills);
      isInitialMount.current = false;
      // console.log(skills);
    }
  }, [shadowUserProfile.Skills]);

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("Skills")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <div className="form--container x-large mb-40">
        <DndProvider backend={HTML5Backend}>
          <UserSkillsEditAddSkill skills={skills} setSkills={setSkills} />
          <div className="edit-skills--rate-skills">
            <UserSkillsEditRateSkills skills={skills} setSkills={setSkills} />
          </div>
        </DndProvider>
      </div>
      <div className="edit-dialog--buttons">
        <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
          <IconClose className="icon-size--20" />
          {t("Cancel")}
        </DeliwiButton>
        <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={saveNewValues} disabled={props.saving}>
          <IconCheckmark />
          {t("SaveChanges")}
        </DeliwiButton>
      </div>
    </>
  );
};
