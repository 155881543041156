import { ICompany, ILocation, IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNullOrEmpty } from "../../../utils/validators";
import { t } from "i18next";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface ICompanyInformationFormProps {
  CompanyName: string;
  CompanyCity: string;
  CompanyCountry: string;
  CompanyDepartment: string;
  WorkingSpaceLocation: string;
  WorkingSpaceCountry: string;
  JobTitle: string;
  Supervisor: string;
}

export const UserCompanyInformationEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;

  const validationSchema = Yup.object({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      CompanyName: shadowUserProfile?.Company?.Name || "",
      CompanyCity: shadowUserProfile?.Company?.Location?.City || "",
      CompanyCountry: shadowUserProfile?.Company?.Location?.Country || "",
      CompanyDepartment: shadowUserProfile?.Company?.Department || "",
      WorkingSpaceLocation: shadowUserProfile?.Location?.City || "",
      WorkingSpaceCountry: shadowUserProfile?.Location?.Country || "",
      JobTitle: shadowUserProfile?.JobTitle,
      Supervisor: shadowUserProfile?.Supervisor,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
    },
  });

  const saveNewValues = (values: ICompanyInformationFormProps) => {
    const updatedProfile = shadowUserProfile as IUserProfile;
    if (isNullOrEmpty(updatedProfile.Company)) updatedProfile.Company = { Location: {} } as ICompany;
    if (isNullOrEmpty(updatedProfile.Location)) updatedProfile.Location = {} as ILocation;
    updatedProfile.Company.Name = values.CompanyName;
    updatedProfile.Company.Location.City = values.CompanyCity;
    updatedProfile.Company.Location.Country = values.CompanyCountry;
    updatedProfile.Company.Department = values.CompanyDepartment;
    updatedProfile.Location.City = values.WorkingSpaceLocation;
    updatedProfile.Location.Country = values.WorkingSpaceCountry;
    updatedProfile.JobTitle = values.JobTitle;
    updatedProfile.Supervisor = values.Supervisor;

    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("CompanyInformation")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-40">
          <div className="form--fields-container form--fields-container-grid">
            <div className="form--field--wrapper">
              <TextField label="Company: Name" id="CompanyName" name="CompanyName" defaultValue={formik.values.CompanyName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Company: City" id="CompanyCity" name="CompanyCity" defaultValue={formik.values.CompanyCity} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Company: Country" id="CompanyCountry" name="CompanyCountry" defaultValue={formik.values.CompanyCountry} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Company: Department" id="CompanyDepartment" name="CompanyDepartment" defaultValue={formik.values.CompanyDepartment} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Working space location: City" id="WorkingSpaceLocation" name="WorkingSpaceLocation" defaultValue={formik.values.WorkingSpaceLocation} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Working space location: Country" id="WorkingSpaceCountry" name="WorkingSpaceCountry" defaultValue={formik.values.WorkingSpaceCountry} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Job title" id="JobTitle" name="JobTitle" defaultValue={formik.values.JobTitle} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Supervisor" id="Supervisor" name="Supervisor" defaultValue={formik.values.Supervisor} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>
          </div>
        </div>
        <div className="edit-dialog--buttons">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
            <IconCheckmark />
            {t("SaveChanges")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
