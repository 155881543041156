import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAddCircle = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10C5 9.65482 5.27982 9.375 5.625 9.375H9.375V5.625C9.375 5.27982 9.65482 5 10 5C10.3451 5 10.625 5.27982 10.625 5.625V9.375H14.375C14.7201 9.375 15 9.65482 15 10C15 10.3451 14.7201 10.625 14.375 10.625H10.625V14.375C10.625 14.7201 10.3451 15 10 15C9.65482 15 9.375 14.7201 9.375 14.375V10.625H5.625C5.27982 10.625 5 10.3451 5 10ZM10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20ZM10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75Z" fill="white" />
      </svg>
    </span>
  );
};
