import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconUpload = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C10.8166 0 12.4145 1.92329 12.6469 4.24599H12.7179C14.5306 4.24599 16 5.75792 16 7.62299C16 7.71829 15.9962 7.81267 15.9886 7.90598C15.6666 7.50435 15.2919 7.14686 14.8748 6.84404C14.5644 5.91106 13.7121 5.24599 12.718 5.24599H12.6469C12.1332 5.24601 11.703 4.85673 11.6518 4.34554C11.4497 2.32493 10.1085 1 8 1C5.88606 1 4.55108 2.31588 4.34818 4.34547C4.29707 4.85669 3.86688 5.24601 3.3531 5.24599H3.28207C2.02819 5.24599 1 6.30392 1 7.62299C1 8.94206 2.02819 10 3.28205 10H5.94761C5.86058 10.323 5.80097 10.6572 5.77144 11H3.28205C1.46942 11 0 9.48806 0 7.62299C0 5.82009 1.3731 4.34717 3.10199 4.25098L3.35314 4.24599C3.58687 1.90802 5.18335 0 8 0ZM11.5 7C13.9853 7 16 9.01472 16 11.5C16 13.9853 13.9853 16 11.5 16C9.01472 16 7 13.9853 7 11.5C7 9.01472 9.01472 7 11.5 7ZM13.1023 11.8977C13.3219 12.1174 13.6781 12.1174 13.8977 11.8977C14.1174 11.6781 14.1174 11.3219 13.8977 11.1023L11.8977 9.10225C11.6781 8.88258 11.3219 8.88258 11.1023 9.10225L9.10225 11.1023C8.88258 11.3219 8.88258 11.6781 9.10225 11.8977C9.32192 12.1174 9.67808 12.1174 9.89775 11.8977L11 10.7955V13.5C11 13.7761 11.2239 14 11.5 14C11.7761 14 12 13.7761 12 13.5V10.7955L13.1023 11.8977Z" fill="#242424" />
      </svg>
    </span>
  );
};
