import { IconOnline } from "../Icons/IconOnline";
import { IconAway } from "../Icons/IconAway";
import { IconBusy } from "../Icons/IconBusy";
import { IconUnknown } from "../Icons/IconUnknown";
import { IconOffline } from "../Icons/IconOffline";

interface IUserAvailabilityProps {
  availability?: string;
}

export const UserAvailability = (props: IUserAvailabilityProps) => {
  const { availability } = props;

  return <div className="user-profile-overview--availability">{availability === "Online" ? <IconOnline /> : availability === "Away" ? <IconAway /> : availability === "Busy" ? <IconBusy /> : availability === "Offline" ? <IconOffline /> : <IconUnknown />}</div>;
};
