import React, { useState, useCallback, ChangeEvent } from "react";
import { t } from "i18next";
import Cropper, { Area, Point } from "react-easy-crop";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { Slider } from "@fluentui/react/lib/Slider";
import { updateProfilePicture } from "services/apiService";
import { IUserProfile } from "interfaces/IUserProfile";
import { DeliwiButton } from "components/DeliwiButton";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";
import { IconUpload } from "components/Icons/IconUpload";
import "assets/styles/components/Forms.scss";

export interface IUserProfilePictureEditForm {
  userProfileData: IUserProfile;

  showPopup(): void;

  hidePopup(): void;

  updatedProfilePictureSetter(data: string | undefined): void;

  updatedProfilePicture: string | undefined;
}

interface ICroppedImageResult {
  file: File;
  fileUrl: string;
  fileName: string;
}

export const UserProfilePictureEdit = (props: IUserProfilePictureEditForm) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1.4);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState<string | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [imageName, setImageName] = useState<string>();
  const [saving, setSaving] = useState(false);

  const getCroppedImg = async (imageSrc: string, crop: Area): Promise<ICroppedImageResult> => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Could not get canvas context");
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    canvas.width = crop.width;
    canvas.height = crop.height;
    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          // Change output format to JPEG and set quality to 90%
          const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
          resolve({ file, fileUrl: URL.createObjectURL(file), fileName: "croppedImage.jpg" });
        },
        "image/jpeg",
        0.9,
      ); // Specify JPEG format and quality here
    });
  };

  const saveProfilePicture = async () => {
    if (croppedAreaPixels && typeof profilePicturePreview === "string") {
      const croppedImage = await getCroppedImg(profilePicturePreview, croppedAreaPixels);
      setSelectedImage(croppedImage.file);
      setImageName(croppedImage.fileName);
      const formData = new FormData();
      formData.append("File", croppedImage.file);
      if (croppedImage.fileName) {
        formData.append("fileName", croppedImage.fileName);
      }
      setSaving(true);
      await updateProfilePicture(formData);
      setSaving(false);
      props.updatedProfilePictureSetter(croppedImage.fileUrl); // Update based on your needs
      setProfilePicturePreview(undefined);
      setCroppedAreaPixels(null); // Reset cropping area
      props.hidePopup();
    } else {
      console.error("No image selected or cropped area info missing");
    }
  };

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Check file type
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        alert("Only JPG and PNG files are allowed.");
        return;
      }

      setSelectedImage(file);
      setImageName(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        // Ensure the result is a string before setting the image source
        if (typeof reader.result === "string") {
          setProfilePicturePreview(reader.result);
        }
      });
    }
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("AddNewProfilePicture")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      {profilePicturePreview ? (
        <>
          <div className="edit-dialog--profilepic">
            <div className="crop-container">
              <Cropper image={profilePicturePreview} crop={crop} zoom={zoom} aspect={1} cropShape="round" cropSize={{ width: 200, height: 200 }} showGrid={false} onCropChange={onCropChange} onCropComplete={onCropComplete} onZoomChange={onZoomChange} />
            </div>
          </div>
        </>
      ) : (
        <div className="edit-dialog--profilepic-empty">
          <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100Z" fill="white" />
            <path d="M100 66.6667C90.7953 66.6667 83.3333 74.1287 83.3333 83.3333C83.3333 92.538 90.7953 100 100 100C109.205 100 116.667 92.538 116.667 83.3333C116.667 74.1287 109.205 66.6667 100 66.6667ZM87.5 83.3333C87.5 76.4298 93.0965 70.8333 100 70.8333C106.903 70.8333 112.5 76.4298 112.5 83.3333C112.5 90.2368 106.903 95.8333 100 95.8333C93.0965 95.8333 87.5 90.2368 87.5 83.3333ZM80.4165 106.667C76.5055 106.667 73.3333 109.835 73.3333 113.748V115C73.3333 121.259 76.5695 125.945 81.5337 128.967C86.4152 131.939 92.9768 133.333 100 133.333C107.023 133.333 113.585 131.939 118.466 128.967C123.431 125.945 126.667 121.259 126.667 115V113.748C126.667 109.835 123.494 106.667 119.584 106.667H80.4165ZM77.5 113.748C77.5 112.138 78.805 110.833 80.4165 110.833H119.584C121.195 110.833 122.5 112.138 122.5 113.748V115C122.5 119.574 120.233 123.013 116.3 125.408C112.284 127.853 106.554 129.167 100 129.167C93.4462 129.167 87.7162 127.853 83.7003 125.408C79.767 123.013 77.5 119.574 77.5 115V113.748Z" fill="#616161" />
          </svg>
        </div>
      )}
      <div className="edit-dialog--profilepic-upload mb-40">
        <DeliwiButton className="button-style--reset">
          <label className="button-style--outline button-size--medium bg-white" htmlFor="image-upload">
            <IconUpload />
            <span>
              {t("SelectNewPhoto")} <span className="fw-400 text-style--Helper">( jpg, png )</span>
            </span>
          </label>
          <input name="avatar" accept="image/jpeg, image/png" id="image-upload" type="file" hidden onChange={onSelectFile} />
        </DeliwiButton>
        {profilePicturePreview && (
          <>
            <div className="controls">
              <Slider label={t("Zoom")} min={0.9} max={3.5} step={0.1} value={zoom} showValue={false} onChange={onZoomChange} />
            </div>
          </>
        )}
      </div>
      <div className="edit-dialog--buttons">
        <DeliwiButton className="button-style--outline button-size--large ml-auto" onClick={() => props.hidePopup()}>
          <IconClose className="icon-size--20" />
          {t("Cancel")}
        </DeliwiButton>
        <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={saveProfilePicture} disabled={saving || !selectedImage || !croppedAreaPixels}>
          <IconCheckmark /> {t("SaveChanges")}
        </DeliwiButton>
      </div>
    </>
  );
};

// Resize and alternative save function for potential future use

// const resizeImage = async (file: File): Promise<File> => {
//   const img = new Image();
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");
//
//   if (!ctx) {
//     throw new Error("Could not get canvas context");
//   }
//
//   const reader = new FileReader();
//
//   // Properly type the Promise to resolve with a File
//   const promise: Promise<File> = new Promise((resolve, reject) => {
//     reader.onload = (e) => {
//       if (typeof e.target?.result === "string") {
//         img.src = e.target.result;
//         img.onload = () => {
//           let width = img.width;
//           let height = img.height;
//
//           // Calculate the new dimensions
//           if (width > height) {
//             if (width > 600) {
//               height *= 600 / width;
//               width = 600;
//             }
//           } else {
//             if (height > 600) {
//               width *= 600 / height;
//               height = 600;
//             }
//           }
//
//           canvas.width = width;
//           canvas.height = height;
//
//           ctx.drawImage(img, 0, 0, width, height);
//
//           canvas.toBlob((blob) => {
//             if (!blob) {
//               reject(new Error("Failed to create blob"));
//               return;
//             }
//             const file = new File([blob], "resizedImage.png", { type: "image/png" });
//             resolve(file);
//           }, "image/png");
//         };
//       } else {
//         reject(new Error("FileReader did not return a string."));
//       }
//     };
//   });
//
//   reader.readAsDataURL(file);
//   return promise;
// };

// const saveProfilePicture = async () => {
//   if (croppedAreaPixels && selectedImage) {
//     try {
//       // Resize the image first
//       const resizedImage = await resizeImage(selectedImage);
//
//       // Prepare the form data with the resized image
//       const formData = new FormData();
//       formData.append("File", resizedImage); // Use the resized image
//       if (imageName) {
//         formData.append("fileName", imageName);
//       }
//
//       // Append crop area information as before
//       formData.append("cropX", String(croppedAreaPixels.x));
//       formData.append("cropY", String(croppedAreaPixels.y));
//       formData.append("cropWidth", String(croppedAreaPixels.width));
//       formData.append("cropHeight", String(croppedAreaPixels.height));
//
//       setSaving(true);
//       await updateProfilePicture(formData);
//       setSaving(false);
//       props.hidePopup();
//     } catch (error) {
//       console.error("Error resizing image: ", error);
//       setSaving(false);
//     }
//   } else {
//     console.error("No image selected or cropped area info missing");
//   }
// };
