import { useRef, useState, useEffect, FormEvent } from "react";
import { t } from "i18next";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";
import { DepartmentPicker } from "components/ControlPanel/DepartmentPicker";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import { IUser } from "interfaces/IUser";
import { IUserProfile } from "interfaces/IUserProfile";
import { SingleSkillPicker } from "components/ControlPanel/SingleSkillPicker";
import { SkillsChart } from "components/ControlPanel/SkillsChart";
import { SkillRateTable } from "components/ControlPanel/SkillRateTable";
import { getAnalysisResults } from "services/apiService";
import { IconBackArrow } from "components/Icons/IconBackArrow";
import { IconRunAnalysis } from "components/Icons/IconRunAnalysis";
import { DeliwiButton } from "components/DeliwiButton";

// Icons imports
import IconAddUsers from "assets/icons/icon-add-users.svg";
import IconPie from "assets/icons/icon-pie.svg";
import IconHat from "assets/icons/icon-analyses-skillhat.svg";
import { IconPieBtn } from "components/Icons/IconPieBtn";
import { IconAdd } from "components/Icons/IconAdd";

interface IAnalysisResults {
  Expert: IUserProfile[] | null;
  ExpertTotal: number;
  Advanced: IUserProfile[] | null;
  AdvancedTotal: number;
  Novice: IUserProfile[] | null;
  NoviceTotal: number;
  NoInformation: IUserProfile[] | null;
  NoInformationTotal: number;
}

export const RunAnalysis = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [fillWidth, setFillWidth] = useState<string>("0%");
  const totalSteps = 3;
  const [peopleTotal, setPeopleTotal] = useState<number>(0);
  const [analysisType, setAnalysisType] = useState<string>("all");
  const [selectedAnalyticsUsers, setSelectedAnalyticsUsers] = useState<IUser[]>([]);
  const [analysisResults, setAnalysisResults] = useState<IAnalysisResults | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const peoplePickerRef = useRef<PeoplePickerRef>(null);
  const [selectedSkill, setSelectedSkill] = useState<string>("");
  const [isAnalysisRunning, setIsAnalysisRunning] = useState<boolean>(false);

  const handleUserSelection = (users: IPersonaProps[]) => {
    const newSelectedAnalyticsUsers: IUser[] = users.map((user) => ({
      DisplayName: user.text || "",
      Id: user.key as string,
      Type: user.secondaryText || "",
    }));
    setSelectedAnalyticsUsers(newSelectedAnalyticsUsers);
  };

  const handleDepartmentSelection = (department: string) => {
    setSelectedDepartment(department);
  };

  const handleRadioChange = (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption) => {
    if (option) {
      setAnalysisType(option.key);
    }
  };

  const radioOptions: IChoiceGroupOption[] = [
    { key: "all", text: "All users" },
    { key: "department", text: "By department" },
    { key: "users", text: "Specific users" },
  ];

  useEffect(() => {
    const newFillWidth = calculateFillWidth(currentStep, totalSteps);
    if (currentStep === 3) {
      setFillWidth("100%");
    } else {
      setFillWidth(newFillWidth);
    }
  }, [currentStep, totalSteps]);

  const calculateFillWidth = (currentStep: number, totalSteps: number): string => {
    return `${((currentStep - 0.5) / totalSteps) * 100}%`;
  };

  const fetchAnalysisResults = async (skillName: string, department: string = "", userIds: string[] = []): Promise<IAnalysisResults> => {
    try {
      const analysisResults = await getAnalysisResults(skillName, department, userIds);
      return analysisResults;
    } catch (error) {
      console.error("Failed to fetch analysis results:", error);
      return {
        Expert: null,
        ExpertTotal: 0,
        Advanced: null,
        AdvancedTotal: 0,
        Novice: null,
        NoviceTotal: 0,
        NoInformation: null,
        NoInformationTotal: 0,
      };
    }
  };

  const handleRunAnalysisClick = async (): Promise<void> => {
    setIsAnalysisRunning(true);
    const minimumLoadingTime = new Promise<void>((resolve) => setTimeout(resolve, 1000));

    let userIds: string[] = [];
    if (analysisType === "users") {
      userIds = selectedAnalyticsUsers.map((user) => user.Id);
    }

    const analysisResults = await fetchAnalysisResults(selectedSkill, selectedDepartment, userIds);
    setAnalysisResults(analysisResults);

    const count = analysisResults.ExpertTotal + analysisResults.AdvancedTotal + analysisResults.NoviceTotal + analysisResults.NoInformationTotal;

    setPeopleTotal(count);

    await minimumLoadingTime;
    setIsAnalysisRunning(false);
    setCurrentStep(3);
  };

  const isContinueEnabled = analysisType === "all" || selectedAnalyticsUsers.length > 0 || selectedDepartment.length > 0;

  const renderStepIndicator = () => {
    const stepTitles = [t("SelectUsers"), t("ChooseSkillAndRunAnalysis"), t("Results")];

    return (
      <div className="mb-48">
        <div className="admin-analyses__steps">
          {stepTitles.map((title, index) => {
            const isCompleted = index < currentStep;
            const isActive = index === currentStep - 1;
            return (
              <div key={title} className={`text-style--Subtitle2 text-style--bold step ${isActive ? "active" : ""} ${isCompleted ? "completed" : ""}`}>
                <span className="step-label">{title}</span>
              </div>
            );
          })}
        </div>
        <div className="admin-analyses__bar">
          <div className="admin-analyses__bar--fill" style={{ width: fillWidth }}></div>
        </div>
      </div>
    );
  };

  const renderDialogContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="admin-box__iconset mb-24">
              <img src={IconAddUsers} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("SelectTheUsersToAnalyze")}</h4>
            </div>
            <div className="info-text mb-40">
              <p className="text-style--Subtitle2 text-style--semibold" dangerouslySetInnerHTML={{ __html: t("AnalysisExplanation") }} />
            </div>
            <ChoiceGroup className="admin-analyses__radios mb-24" selectedKey={analysisType} options={radioOptions} onChange={handleRadioChange} />

            {analysisType === "users" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">Select users *</p>
                <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} pickerType="analysisusers" />
              </div>
            )}

            {analysisType === "department" && (
              <div className="mb-40">
                <p className="text-style--Body2 text-color--grey44 mb-8">Select department *</p>
                <DepartmentPicker onDepartmentSelect={handleDepartmentSelection} />
              </div>
            )}
            <div className="admin-analyses__buttons">
              <DeliwiButton disabled={!isContinueEnabled} className="button-style--primary button-size--large ml-auto" onClick={() => setCurrentStep(2)}>
                <IconPieBtn className="icon-size--20" />
                {t("Continue")}
              </DeliwiButton>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="admin-box__iconset mb-40">
              <img src={IconHat} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("ChooseTheSkillToAnalyze")}</h4>
            </div>
            <div className="admin-analyses__steps--skills mb-40">
              <SingleSkillPicker onSkillSelect={setSelectedSkill} />
              <div className="info-text">
                <p className="text-style--semibold">Start typing skill name and select skill from the list.</p>
                <p className="text-style--semibold">OR</p>
                <p className="text-style--semibold">Type the skillname if it is not found in the list.</p>
              </div>
            </div>
            <div className="admin-analyses__buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => setCurrentStep(1)}>
                <IconBackArrow className="icon-size--18" />
                {t("Back")}
              </DeliwiButton>
              {isAnalysisRunning ? (
                <div className="ml-auto">
                  <div className="deliwi-spinner"></div>
                </div>
              ) : (
                <DeliwiButton className="button-style--primary button-size--large ml-auto" onClick={handleRunAnalysisClick} disabled={!selectedSkill}>
                  <IconRunAnalysis className="icon-size--18" />
                  {t("RunAnalysis")}
                </DeliwiButton>
              )}
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="admin-box__iconset mb-32">
              <img src={IconPie} className="icon-size-62 icon-shadow" alt="" />
              <h4 className="text-style--Title2 text-style--semibold">{t("AnalyzeTitle")}</h4>
            </div>
            <div className="admin-analyses__results mb-40">
              <div className="admin-analyses__graph">{analysisResults && <SkillsChart analysisResults={analysisResults} />}</div>
              <div className="admin-analyses__info">
                <div className="admin-box">
                  <h4 className="text-style--Subtitle1 mb-32 admin-analyses__info--title">Analysis info</h4>
                  <p className="text-style--Body2 text-color--grey44 mb-4">Users in analysis:</p>
                  <p className="text-style--Title3 text-style--semibold mb-24">{peopleTotal} users</p>
                  <p className="text-style--Body2 text-color--grey44 mb-4">The skill under survey:</p>
                  <p className="text-style--Title3 text-style--semibold mb-24">{selectedSkill}</p>
                  {/*<p className="text-style--Body1 text-color--neutral-foreground">If the analysis indicates substantial “no data available”, it is advised to create survey for a more comprehensive understanding</p>*/}
                </div>
              </div>
            </div>
            <div className="admin-analyses__table mb-40">{analysisResults && <SkillRateTable analysisResults={analysisResults} />}</div>
            <div className="admin-analyses__buttons">
              <DeliwiButton
                className="button-style--primary button-size--large ml-auto"
                onClick={() => {
                  setCurrentStep(1);
                  setIsAnalysisRunning(false);
                  setSelectedSkill("");
                  setSelectedDepartment("");
                  setSelectedAnalyticsUsers([]);
                }}
              >
                <IconAdd className="icon-size--18" />
                {t("RunNewAnalysis")}
              </DeliwiButton>
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {renderStepIndicator()}
      {renderDialogContent()}
    </>
  );
};
