import PropTypes from "prop-types";

export const Size32ThemeRegular4 = ({ color = "#242424", className }) => {
  return (
    <svg
      className={`size-32-theme-regular-4 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.5 2C3.31062 2 3.13749 2.107 3.05279 2.27639L1.05279 6.27639C0.96496 6.45206 0.988101 6.66287 1.11195 6.8153L7.61195 14.8153C7.70689 14.9322 7.84944 15 8 15C8.15057 15 8.29312 14.9322 8.38806 14.8153L14.8881 6.8153C15.0119 6.66287 15.035 6.45206 14.9472 6.27639L12.9472 2.27639C12.8625 2.107 12.6894 2 12.5 2H3.5ZM2.30902 6L3.80902 3H5.85972L5.10972 6H2.30902ZM2.55049 7H5.13251L6.74627 12.164L2.55049 7ZM6.1802 7H9.81985L8.00002 12.8234L6.1802 7ZM10.8675 7H13.4495L9.25381 12.164L10.8675 7ZM13.691 6H10.8903L10.1403 3H12.191L13.691 6ZM9.85956 6H6.14049L6.89049 3H9.10956L9.85956 6Z"
        fill={color}
      />
    </svg>
  );
};

Size32ThemeRegular4.propTypes = {
  color: PropTypes.string,
};

export default Size32ThemeRegular4;