import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { apis } from "msalConfig";
import { IUserProfile } from "interfaces/IUserProfile";
import { IUser, IUserResults, IUserProfilesResponse } from "interfaces/IUser";
import { InitialSetup, InitialSetupConfig, InitialSetupGroups } from "interfaces/InitialSetup";
import { IApi } from "interfaces/IApi";

// Initialization

let msalInstance: IPublicClientApplication;

export const setMsalInstance = async (instance: IPublicClientApplication) => {
  msalInstance = instance;
  await msalInstance.initialize();
};

// Private methods

const getAccessToken = async (scopes: string[]): Promise<string | null> => {
  let authResult = null;

  const account = msalInstance.getActiveAccount() ?? msalInstance.getAllAccounts()[0];

  try {
    authResult = await msalInstance.acquireTokenSilent({ scopes: scopes, account: account });
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      authResult = await msalInstance.acquireTokenPopup({ scopes: scopes });
    }
  }

  return authResult ? authResult.accessToken : null;
};

const getHeaders = async (scopes: string[], contentType?: string) => {
  const headers = new Headers();
  const accessToken = await getAccessToken(scopes);
  headers.append("Authorization", `Bearer ${accessToken}`);
  if (contentType !== "remove-content-type") {
    headers.append("Content-Type", contentType ?? "application/json");
  }

  return headers;
};

const makeRequest = async (api: IApi, url: string, method: string, body?: any, contentType?: string, customHeaders?: Record<string, string>) => {
  url = `${api.endpoint}${url}`;
  let headers = await getHeaders(api.scopes, contentType);

  // Correctly add custom headers if provided
  if (customHeaders) {
    Object.keys(customHeaders).forEach((key) => {
      headers.set(key, customHeaders[key]);
    });
  }

  const options = {
    method: method,
    headers: headers,
    body: body && contentType !== "remove-content-type" ? JSON.stringify(body) : body,
  };

  const response = await fetch(url, options);

  try {
    return await response?.json();
  } catch (e) {
    console.error("Error making request:", e);
    return null;
  }
};
const get = async (api: IApi, url: string, customHeaders?: Record<string, string>) => {
  return await makeRequest(api, url, "GET", undefined, undefined, customHeaders);
};

const patch = async (api: IApi, url: string, body: any) => {
  return await makeRequest(api, url, "PATCH", body);
};

const post = async (api: IApi, url: string, body: any, contentType?: string) => {
  return await makeRequest(api, url, "POST", body, contentType);
};

const del = async (api: IApi, url: string) => {
  return await makeRequest(api, url, "DELETE");
};

// Public methods

export const getUserProfile = async (): Promise<IUserProfile> => {
  return await get(apis.customerApi, "/UserProfile");
};

export const getUserProfileByIdentifier = async (identifier: string): Promise<IUserProfile> => {
  return await get(apis.customerApi, "/UserProfile?userIdOrPrincipalName=" + encodeURIComponent(identifier));
};

export const getUserProfiles = async (offset: number, limit: number, searchTerm?: string): Promise<IUserProfilesResponse> => {
  let url = `/UserProfiles?offset=${offset}&limit=${limit}`;
  if (searchTerm && searchTerm.trim() !== "") {
    url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  return await get(apis.customerApi, url);
};

export const getAnalysisUsers = async (offset: number, limit: number, searchTerm?: string): Promise<IUserProfilesResponse> => {
  let url = `/UserProfiles?offset=${offset}&limit=${limit}`;
  if (searchTerm && searchTerm.trim() !== "") {
    url += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  return await get(apis.customerApi, url);
};

export const saveUserProfile = async (userProfile: IUserProfile): Promise<void> => {
  return await patch(apis.customerApi, "/UserProfile", userProfile);
};

export const deleteUserProfile = async (Id: string): Promise<void> => {
  return await del(apis.customerApi, `/UserProfile?id=${Id}`);
};

export const getAnalysisResults = async (skillName: string, department?: string, userIds?: string[]) => {
  // Construct the URL with query parameters for skillName
  let url = `/Analysis?skillName=${encodeURIComponent(skillName)}`;

  // Add department to the URL only if it's provided
  if (department) {
    url += `&department=${encodeURIComponent(department)}`;
  }

  // Prepare the request body. Include userIds if provided and not empty
  const hasUserIds = userIds && userIds.length > 0;
  const requestBody = hasUserIds ? userIds : [];

  // Assuming `post` is a custom method for making POST requests.
  // Modify the method call according to how your `post` method is defined, especially regarding handling of `undefined` requestBody
  return await post(apis.customerApi, url, requestBody);
};

export const getUserProfilesDepartments = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/UserProfiles/Departments?searchTerm=${searchString}`);
};

export const searchUsersGroups = async (searchString: string): Promise<IUserResults> => {
  return await get(apis.customerApi, `/UsersAndGroupsSearch?searchString=${searchString}`);
};

export const searchUserProfiles = async (searchString: string): Promise<IUserProfile[]> => {
  return await get(apis.customerApi, `/UserSearch?searchString=${searchString}`);
};

export const getUserRole = async (): Promise<string[]> => {
  return await get(apis.customerApi, "/UserRole");
};

export const sendUserLoginTime = async (): Promise<string[]> => {
  const customHeaders = {
    UpdateLastLogIn: "true",
  };

  return await get(apis.customerApi, "/UserRole", customHeaders);
};

export const inviteUsers = async (users: IUser[]): Promise<any> => {
  return await post(apis.customerApi, `/Invite`, users);
};

export const getTeamsBotStatus = async (): Promise<{ TeamsBotEnabled: boolean }> => {
  return await get(apis.customerApi, "/AdminConfiguration");
};

export const updateTeamsBotStatus = async (data: { InviteMessageTemplate: string; TeamsBotEnabled: boolean }) => {
  return await post(apis.customerApi, "/AdminConfiguration", data);
};

export const updateProfilePicture = async (data: FormData) => {
  return await post(apis.customerApi, "/ProfilePicture", data, "remove-content-type");
};

export const getProfilePicture = async (userid: string): Promise<string[]> => {
  return await get(apis.customerApi, `/ProfilePicture?userProfileId=${userid}`);
};

export const searchSkills = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/Skills?query=${searchString}`);
};

export const searchInterests = async (searchString: string): Promise<string[]> => {
  return await get(apis.customerApi, `/InterestKeyword?query=${searchString}`);
};

export const sendUsersLinkedInData = async (linkedInConsent: boolean, profileUrl: string) => {
  return await post(apis.customerApi, `/LinkedIn?giveLinkedInSearchConsent=${linkedInConsent}&linkedInProfileUrl=${profileUrl}`, "");
};

export const adminUpdatePermissions = async (userProfileId: string, permissions: number[]): Promise<void> => {
  return await patch(apis.customerApi, `/AdminConfiguration/UserProfiles/${userProfileId}/Permissions`, permissions);
};

export const adminUpdateVisibility = async (userProfileId: string, visibility: boolean): Promise<void> => {
  return await patch(apis.customerApi, `/AdminConfiguration/UserProfiles/${userProfileId}/Visibility?visibility=${visibility}`, { visibility });
};

export const getInitialSetupStatus = async (): Promise<InitialSetup> => {
  return await get(apis.customerApi, "/InitialSetup/Status");
};

export const postInitialSetupConfigurations = async (config: InitialSetupConfig) => {
  // console.log(config);
  return await post(apis.customerApi, `/InitialSetup/Configurations`, config);
};

export const postInitialSetupGroups = async (groups: InitialSetupGroups) => {
  // console.log(groups);
  return await post(apis.customerApi, `/InitialSetup/AuthorizationGroups`, groups);
};

export const postInitialSetupAdmins = async (userIds: string[]): Promise<any> => {
  // console.log(userIds);
  return await post(apis.customerApi, `/InitialSetup/Admins`, userIds);
};

export const getInitialSetupUsers = async (searchTerm: string): Promise<IUserResults> => {
  return await get(apis.customerApi, `/InitialSetup/Users?searchTerm=${searchTerm}`);
};

export const getUserAvailability = async (userId: string): Promise<any> => {
  return await get(apis.graphApi, `/users/${userId}/presence`);
};
