import React, { ReactNode } from "react";

/**
 * Converts newline characters in a text string to <br /> elements for rendering in React.
 *
 * @param {string} text The text to convert.
 * @returns {Array<ReactNode>} An array of strings and <br /> elements suitable for rendering in JSX.
 */
function textWithLineBreaks(text: string): Array<ReactNode> {
  return text.split("\n").map((line, index, array) => (
    <React.Fragment key={index}>
      {line}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
}

export default textWithLineBreaks;
