import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconLogout = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33333 18.6667H10.6667V20H0V0H10.6667V1.33333H1.33333V18.6667ZM18.8229 10L13.75 15.0833L12.9167 14.25L16.5104 10.6667H5.33333V9.33333H16.5104L12.9167 5.75L13.75 4.91667L18.8229 10Z" fill="black" />
      </svg>
    </span>
  );
};
