import { TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface ICurrentWorkFormProps {
  JobDescription: string;
}

export const UserCurrentWorkEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;

  const validationSchema = Yup.object({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      JobDescription: shadowUserProfile?.JobDescription || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
    },
  });

  const saveNewValues = (values: ICurrentWorkFormProps) => {
    const updatedProfile = {
      ...shadowUserProfile,
      JobDescription: values.JobDescription,
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper large">
        <h2 className="form--heading">{t("JobDescription")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-40">
          <div className="form--fields-container form--fields-container-flexcol">
            <div className="form--fields-container flex-column">
              <div className="form--field--wrapper">
                <TextField label={t("ShortJobDescription")} id="JobDescription" name="JobDescription" multiline autoAdjustHeight rows={2} defaultValue={formik.values.JobDescription} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </div>
          </div>
        </div>
        <div className="edit-dialog--buttons">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
            <IconCheckmark />
            {t("SaveChanges")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
