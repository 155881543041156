import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconMyProfile = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Person">
          <path id="Shape" d="M17.7542 13.9992C18.9962 13.9992 20.003 15.0061 20.003 16.2481V16.8235C20.003 17.7178 19.6835 18.5826 19.1019 19.262C17.5326 21.0955 15.1454 22.0004 12 22.0004C8.85414 22.0004 6.46812 21.0952 4.90182 19.2609C4.32206 18.582 4.00354 17.7185 4.00354 16.8258V16.2481C4.00354 15.0061 5.0104 13.9992 6.25242 13.9992H17.7542ZM17.7542 15.4992H6.25242C5.83882 15.4992 5.50354 15.8345 5.50354 16.2481V16.8258C5.50354 17.3614 5.69465 17.8795 6.04251 18.2869C7.29582 19.7546 9.26169 20.5004 12 20.5004C14.7383 20.5004 16.7059 19.7545 17.9624 18.2866C18.3113 17.879 18.503 17.3601 18.503 16.8235V16.2481C18.503 15.8345 18.1678 15.4992 17.7542 15.4992ZM12 2.00391C14.7614 2.00391 17 4.24248 17 7.00391C17 9.76533 14.7614 12.0039 12 12.0039C9.23857 12.0039 7 9.76533 7 7.00391C7 4.24248 9.23857 2.00391 12 2.00391ZM12 3.50391C10.067 3.50391 8.5 5.07091 8.5 7.00391C8.5 8.9369 10.067 10.5039 12 10.5039C13.933 10.5039 15.5 8.9369 15.5 7.00391C15.5 5.07091 13.933 3.50391 12 3.50391Z" />
        </g>
      </svg>
    </span>
  );
};
