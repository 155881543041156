import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAway = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Shape" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 8.4116V13.4207L17.7899 17.2106C18.3367 17.7574 18.3367 18.6438 17.7899 19.1905C17.2432 19.7373 16.3568 19.7373 15.8101 19.1905L11.6101 14.9905C11.3475 14.728 11.2 14.3719 11.2 14.0006V8.4116C11.2 7.6384 11.8268 7.0116 12.6 7.0116C13.3732 7.0116 14 7.6384 14 8.4116Z" fill="#EAA300" />
      </svg>
    </span>
  );
};
