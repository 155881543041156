import "../../assets/styles/components/FillRateIndicator.scss";
import { IconBlur } from "../Icons/IconBlur";
import { IconEmojiHint } from "../Icons/IconEmojiHint";
import { IconStarEmphasis } from "../Icons/IconStarEmphasis";
import { IconRibbonStar } from "../Icons/IconRibbonStar";
import { IconRocket } from "../Icons/IconRocket";
import { t } from "i18next";

interface IFillRateIndicatorProps {
  fillRate: number;
}

export const FillRateIndicator = ({ fillRate }: IFillRateIndicatorProps) => {
  let fillRateIcon = <IconBlur />;
  let fillRateName = t("FillRateTitles.Level1");

  if (fillRate >= 25 && fillRate < 50) {
    fillRateIcon = <IconEmojiHint />;
    fillRateName = t("FillRateTitles.Level2");
  } else if (fillRate >= 50 && fillRate < 75) {
    fillRateIcon = <IconStarEmphasis />;
    fillRateName = t("FillRateTitles.Level3");
  } else if (fillRate >= 75 && fillRate < 90) {
    fillRateIcon = <IconRibbonStar />;
    fillRateName = t("FillRateTitles.Level4");
  } else if (fillRate >= 90) {
    fillRateIcon = <IconRocket />;
    fillRateName = t("FillRateTitles.Level5");
  }
  return (
    <div className="score-wrap">
      <div className="score">
        <div className="score-bar">
          <div className="placeholder">{progressBar(100)}</div>

          <div className="score-circle">{progressBar(fillRate, true)}</div>
        </div>

        <div className="score-value">
          <div className="score-icon">{fillRateIcon}</div>
          <div className="score-level-name text-style--Subtitle2">{fillRateName}</div>
          <div className="score-title">{t("ProfileFillRate")}</div>
          <div className="score-number text-style--Subtitle2">{Math.round(fillRate)}%</div>
        </div>
      </div>
    </div>
  );
};

function progressBar(widthPerc: number, gradient: boolean = false) {
  const radius = 75;

  const dashArray = (Math.PI * radius * widthPerc) / 100;

  return (
    <svg width="200" height="120">
      <circle cx="100" cy="85" r={radius} fill="none" strokeWidth="20" strokeLinecap="round" strokeDashoffset={-1 * Math.PI * radius} strokeDasharray={`${dashArray} 10000`} stroke={gradient ? "url(#score-gradient)" : "#FFF"}></circle>

      {gradient && (
        <defs>
          <linearGradient id="score-gradient">
            <stop offset="0%" stopColor="#0614DF" />

            <stop offset={`${widthPerc}%`} stopColor="#27A9A3" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
}
