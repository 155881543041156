import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconStar = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Star">
          <path id="Shape" d="M5.28347 1.54751C5.57692 0.952913 6.42479 0.952914 6.71825 1.54751L7.82997 3.80012L10.3159 4.16134C10.9721 4.25669 11.2341 5.06307 10.7592 5.5259L8.96043 7.27931L9.38507 9.75517C9.49716 10.4087 8.81122 10.9071 8.22431 10.5985L6.00086 9.42957L3.7774 10.5985C3.19049 10.9071 2.50455 10.4087 2.61664 9.75517L3.04128 7.27931L1.24246 5.5259C0.767651 5.06307 1.02966 4.25669 1.68584 4.16134L4.17174 3.80012L5.28347 1.54751Z" fill="#08757A" />
        </g>
      </svg>
    </span>
  );
};
