import { ComboBox, IComboBoxOption, TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import ISO6391 from "iso-639-1";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import "../../../assets/styles/components/Forms.scss";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IProfileInformationFormProps {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  PreferredLanguage: string;
  DateOfBirth: string;
}

export const UserProfileInformationEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;

  const allLanguages = ISO6391.getAllNames();
  const languageOptions: IComboBoxOption[] = [];

  allLanguages.forEach((language) => {
    languageOptions.push({
      key: language,
      text: language,
    });
  });

  // regexp for phone number validation
  const phoneRegExp = /^\+[0-9 ]{9,15}$/;  

  const validationSchema = Yup.object({
    Email: Yup.string().email("Enter a valid email"),
    PhoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirstName: shadowUserProfile?.FirstName || "",
      LastName: shadowUserProfile?.LastName || "",
      Email: shadowUserProfile?.Email || "",
      PhoneNumber: shadowUserProfile?.PhoneNumber || "",
      PreferredLanguage: shadowUserProfile?.PreferredLanguage || "",
      DateOfBirth: shadowUserProfile?.DateOfBirth || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
    },
  });

  const saveNewValues = (values: IProfileInformationFormProps) => {
    const updatedProfile = {
      ...shadowUserProfile,
      FirstName: values.FirstName,
      LastName: values.LastName,
      Email: values.Email,
      PhoneNumber: values.PhoneNumber,
      PreferredLanguage: values.PreferredLanguage,
      DateOfBirth: "1970-01-01T00:00:00Z",
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("PersonalInformation")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-40">
          <div className="form--fields-container form--fields-container-flexcol">
            <div className="form--field--wrapper">
              <TextField label="First name" id="FirstName" required={true} name="FirstName" defaultValue={formik.values.FirstName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Last name" id="LastName" required={true} name="LastName" defaultValue={formik.values.LastName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label="Email" id="Email" name="Email" defaultValue={formik.values.Email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.errors.Email && formik.touched.Email ? <div className="input-error">{formik.errors.Email}</div> : null}
            </div>

            <div className="form--field--wrapper">
              <TextField label={t("PhoneNumber")} id="PhoneNumber" name="PhoneNumber" defaultValue={formik.values.PhoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              {formik.errors.PhoneNumber && formik.touched.PhoneNumber ? <div className="input-error">{formik.errors.PhoneNumber}</div> : null}
            </div>

            <div className="form--field--wrapper">
              <ComboBox
                label={t("PreferredLanguage")}
                options={languageOptions}
                defaultSelectedKey={formik.values.PreferredLanguage}
                allowFreeform={true}
                onChange={(event, option) => {
                  if (option !== undefined) {
                    formik.setFieldValue(`PreferredLanguage`, option.key);
                  }
                }}
                onBlur={formik.handleBlur}
              />
            </div>

            {/*<div className="form--field--wrapper">
              <DatePicker
                label={t("DateOfBirth")}
                firstDayOfWeek={DayOfWeek.Monday}
                formatDate={formatDate}
                value={!!formik.values.DateOfBirth ? new Date(formik.values.DateOfBirth) : undefined}
                onSelectDate={(date) => {
                  if (date instanceof Date) {
                    const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                    formik.setFieldValue(`DateOfBirth`, dateValue);
                  }
                }}
                onBlur={() => formik.setFieldTouched(`DateOfBirth`, true)}
              />
            </div>*/}
          </div>
        </div>
        <div className="edit-dialog--buttons">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
            <IconCheckmark />
            {t("SaveChanges")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
