import { ReactNode } from "react";

interface DeliwiButtonProps {
  text?: string;
  className?: string;
  value?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

export const DeliwiButton = (props: DeliwiButtonProps) => {
  return (
    <>
      <button type={props.type || "button"} className={props.className || ""} value={props.value} onClick={props.onClick} disabled={props.disabled}>
        <span className="deliwibutton-wrapper">
          {props.children}
          {props.text}
        </span>
      </button>
    </>
  );
};
