import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconMail = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Mail">
          <path id="Shape" d="M5.38324 2.97656C3.8322 2.97656 2.57483 4.23393 2.57483 5.78497V6.06764L11.0002 10.6044L19.4253 6.06779V5.78497C19.4253 4.23393 18.1679 2.97656 16.6169 2.97656H5.38324ZM19.4253 7.66262L11.3331 12.02C11.1253 12.1319 10.8751 12.1319 10.6673 12.02L2.57483 7.66248V14.2102C2.57483 15.7612 3.8322 17.0186 5.38324 17.0186H16.6169C18.1679 17.0186 19.4253 15.7612 19.4253 14.2102V7.66262Z" fill="#020404" />
        </g>
      </svg>
    </span>
  );
};
