import * as React from "react";
import { FocusTrapZone, mergeStyleSets, Overlay, Popup } from "@fluentui/react";
import { t } from "i18next";
import { DeliwiButton } from "components/DeliwiButton";

import "../assets/styles/components/FirstVisitSteps.scss";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IconTrash } from "./Icons/IconTrash";
import { IconDismiss } from "./Icons/IconDismiss";

const popupStyles = mergeStyleSets({
  root: {
    background: "rgba(0, 0, 0, 0.5)",
    bottom: "0",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0",
    zIndex: "11",
  },
  content: {
    background: "white",
    left: "50%",
    maxWidth: "80%",
    width: "880px",
    maxHeight: "80%",
    overflowY: "auto",
    padding: "24px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "24px",
    zIndex: "999",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.20), 0px 32px 64px 0px rgba(0, 0, 0, 0.24)",
  },
});

interface IConfirmDialogProps {
  itemName: string | null;
  itemType: string | null;
  itemTypePlural: string | null;
  confirmDialogOpen: boolean;

  setConfirmDialogOpen(data: boolean): void;

  onConfirm(): void;
}

export const ConfirmDeleteDialog = (props: IConfirmDialogProps) => {
  return (
    <>
      <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={() => props.setConfirmDialogOpen(false)} enableAriaHiddenSiblings={true}>
        <Overlay onClick={() => props.setConfirmDialogOpen(false)} />
        <FocusTrapZone>
          <div role="document" className={popupStyles.content}>
            <div className="confirm-dialog--content--wrapper">
              <div className="form--heading--wrapper">
                <h2 className="form--heading">{t("DeleteDialogTitle", { itemType: props.itemType })}</h2>
                <div className="close-preview--wrapper">
                  <ChromeCloseIcon className="close-edit-popup" onClick={() => props.setConfirmDialogOpen(false)}></ChromeCloseIcon>
                </div>
              </div>
              <div className="form--container mb-40">
                <div className="form--fields-container flex-column">
                  <div className="form--field--wrapper mb-16">
                    <h2 className="text-style--Body1 fw-600">{t("ConfirmRemoveTitle")}</h2>
                  </div>

                  <div className="form--field--wrapper">
                    <p className="text-style--Body1">
                      {t("DeleteDialogText", {
                        itemName: props.itemName,
                        itemType: props.itemTypePlural,
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <div className="edit-dialog--buttons">
                <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.setConfirmDialogOpen(false)}>
                  <IconDismiss className="icon-size--16" />
                  {t("Cancel")}
                </DeliwiButton>
                <DeliwiButton
                  className="button-style--delete button-size--large"
                  onClick={() => {
                    props.setConfirmDialogOpen(false);
                    props.onConfirm();
                  }}
                >
                  <IconTrash className="icon-size--20" />
                  {t("Delete")}
                </DeliwiButton>
              </div>
            </div>
          </div>
        </FocusTrapZone>
      </Popup>
    </>
  );
};
