import { Dispatch, SetStateAction, createContext, useContext, useState, useEffect, ReactNode } from "react";
import { getUserRole } from "../services/apiService";

// Define the context type
interface UserContextType {
  userRole: string[];
  setUserRole: Dispatch<SetStateAction<string[]>>;
}

// Create the context
const UserRoleContext = createContext<UserContextType | undefined>(undefined);

// Create a provider component
export const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const [userRole, setUserRole] = useState<string[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await getUserRole();
      // console.log(response);
      if (Array.isArray(response)) setUserRole(response);
    };
    fetchRoles();
  }, []);

  return <UserRoleContext.Provider value={{ userRole, setUserRole }}>{children}</UserRoleContext.Provider>;
};

// Hook to use the context
export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useUserRole must be used within a UserRoleProvider");
  }
  return context;
};
