import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconBackArrow = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.97478 11.3568C7.3 11.6824 7.29963 12.2101 6.97398 12.5353C6.64832 12.8606 6.12068 12.8602 5.79547 12.5346L0.243676 6.97512C-0.0813585 6.64964 -0.0812055 6.12237 0.244017 5.79708L5.79581 0.244139C6.12121 -0.0813319 6.64884 -0.0813866 6.97432 0.244017C7.29979 0.56942 7.29984 1.09706 6.97444 1.42253L2.84111 5.55556H11.5542C13.3262 5.55556 14.6777 5.82441 15.8753 6.40496L16.1491 6.54449C17.3808 7.20318 18.3523 8.17477 19.0111 9.4064C19.6882 10.6725 20 12.093 20 14.0013C20 14.4616 19.6269 14.8347 19.1667 14.8347C18.7064 14.8347 18.3333 14.4616 18.3333 14.0013C18.3333 12.3527 18.0803 11.2002 17.5413 10.1924C17.038 9.25121 16.3043 8.51753 15.3631 8.01418C14.4328 7.51663 13.3793 7.26283 11.926 7.22673L11.5542 7.22222H2.84667L6.97478 11.3568Z" fill="#242424" />
      </svg>
    </span>
  );
};
