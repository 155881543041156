import { useState, useEffect, MouseEvent, FormEvent, useMemo } from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { IUserData, UsersListProps } from "interfaces/IUser";
import { DetailsList, IDetailsRowProps, DetailsRow, IColumn, Selection, SelectionMode, CheckboxVisibility } from "@fluentui/react/lib/DetailsList";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { DeliwiButton } from "components/DeliwiButton";
import { adminUpdatePermissions, adminUpdateVisibility } from "services/apiService";
import { IconArrowForward } from "../Icons/IconArrowForward";
import { IconEdit } from "../Icons/IconEdit";
import { IconTrash } from "../Icons/IconTrash";
import { IconEye } from "../Icons/IconEye";
import { IconEyeHidden } from "../Icons/IconEyeHidden";
import { HelpDialog } from "components/HelpDialog";
import { IconDismiss } from "../Icons/IconDismiss";

export const UsersList = ({ userProfiles, onDeleteUser, updateUserList, userIdsToDelete }: UsersListProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [sortedItems, setSortedItems] = useState<IUserData[]>([]);
  const [selectedItems, setSelectedItems] = useState<IUserData[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [permissions, setPermissions] = useState<number[]>([]);
  const [visibility, setVisibility] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserData | null>(null);

  const openEditDialog = (user: IUserData) => {
    setSelectedUser(user);
    const firstPermission = user.Permissions && user.Permissions.length > 0 ? [Number(user.Permissions[0])] : [1]; // Wrap the number in an array
    setPermissions(firstPermission);
    setVisibility(user.Visibility || false);
    setIsDialogOpen(true);
  };

  const openEditDialogForSelectedUsers = () => {
    setSelectedUser(null);
    if (selectedItems.length === 1) {
      openEditDialog(selectedItems[0]);
    } else {
      setPermissions([0]);
      setVisibility(true);
      setIsDialogOpen(true);
    }
  };

  const handlePermissionsChange = (ev?: FormEvent<HTMLInputElement | HTMLElement>, option?: IChoiceGroupOption): void => {
    if (option) {
      const permissionValue = Number(option.key);
      setPermissions([permissionValue]); // Wrap permissionValue in an array
    }
  };

  const handleVisibilityChange = (ev: MouseEvent<HTMLElement>, checked?: boolean) => {
    setVisibility(!!checked);
  };

  const handleSaveChanges = async () => {
    // Check if there is at least one selected user
    if (selectedItems.length > 0 || selectedUser) {
      try {
        // If only one user is selected, update that user directly
        if (selectedUser) {
          const user = selectedItems[0];
          // Update Permissions for the single user (if needed)
          await adminUpdatePermissions(user.Id, permissions);

          // Update Visibility for the single user
          await adminUpdateVisibility(user.Id, visibility);
        }
        // If multiple users are selected, iterate and update each
        else {
          await Promise.all(
            selectedItems.map(async (user) => {
              // Update Permissions for each user (if needed)
              await adminUpdatePermissions(user.Id, permissions);

              // Update Visibility for each user
              await adminUpdateVisibility(user.Id, visibility);
            }),
          );
        }

        clearSelection();
        setSelectedUser(null);

        // Handle success - e.g., show a success message, refresh the list, etc.
      } catch (error) {
        // Handle error - e.g., show an error message
        console.error("Error updating user profiles:", error);
      }

      setIsDialogOpen(false);
      updateUserList();
    }
  };

  useEffect(() => {
    setColumns(buildColumnsWithClickHandler());
  }, []);

  useEffect(() => {
    setSortedItems(userProfiles);
    clearSelection(); // Clear selection whenever userProfiles changes
  }, [userProfiles]);

  const onColumnClick = (event: MouseEvent<HTMLElement>, column: IColumn): void => {
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    // Use a custom key for sorting if the column is "User Name"
    const sortKey = column.key === "userName" ? "userName" : column.fieldName;

    const newSortedItems = _copyAndSort(sortedItems, sortKey!, isSortedDescending);
    setSortedItems(newSortedItems);
    setColumns((cols) =>
      cols.map((col) => ({
        ...col,
        isSorted: col.key === column.key,
        isSortedDescending: col.key === column.key ? isSortedDescending : col.isSortedDescending,
      })),
    );
  };

  columns.forEach((c) => (c.onColumnClick = onColumnClick));

  const selection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => {
          const newSelectedItems = selection.getSelection() as IUserData[];
          setSelectedItems(newSelectedItems);
        },
      }),
    [],
  );

  const getEmailsFromSelectedItems = () => {
    return selectedItems.map((item) => item.Email).join(",");
  };

  const clearSelection = () => {
    selection.setAllSelected(false);
    setSelectedItems([]);
    setSelectedUser(null);
  };

  const handleSendEmails = () => {
    const emailList = getEmailsFromSelectedItems();
    if (emailList) {
      window.location.href = `mailto:${emailList}`;
      clearSelection();
    }
  };

  const handleDeleteUsers = () => {
    const userIds = selectedItems.map((item) => item.Id).join(",");
    userIdsToDelete(userIds);
  };

  const onRenderRow = (props?: IDetailsRowProps) => {
    if (!props) return null;

    return (
      <div>
        <DetailsRow {...props} />
      </div>
    );
  };

  const dialogContentProps = {
    type: DialogType.normal,
    title: t("EditUserOrUsers"),
    closeButtonAriaLabel: t("Close"),
  };
  const _renderItemColumn = (item: IUserData, index?: number, column?: IColumn): JSX.Element => {
    const fieldContent = item[column!.fieldName as keyof IUserData];

    if (Array.isArray(fieldContent)) {
      switch (column!.key) {
        case "permissions":
          const roleMap: Record<number, string> = { 1: "User", 2: "SuperUser", 10: "Admin" };
          const roleNames = fieldContent.map((value) => {
            // Check if the value is a number and exists in the roleMap, otherwise default to "User"
            return typeof value === "number" && roleMap[value] !== undefined ? roleMap[value] : "User";
          });
          return <span>{roleNames.join(", ")}</span>;

        default:
          if (!fieldContent) return <span>-</span>;
          return <span>{fieldContent}</span>;
      }
    } else {
      switch (column!.key) {
        case "userName":
          if (!item.FirstName && !item.LastName) {
            return (
              <span className="has-email" data-email={item.Email}>
                {item.Email}
              </span>
            );
          } else {
            const userName = `${item.FirstName} ${item.LastName}`;
            return (
              <span className="has-email" data-email={item.Email}>
                {userName}
              </span>
            );
          }

        case "permissions":
          return <span>User</span>;

        case "department":
          if (!fieldContent) return <span></span>;
          return <span>{fieldContent}</span>;

        case "fillrate":
          if (!fieldContent) return <span>0%</span>;
          return <span>{fieldContent}%</span>;

        case "visibility":
          if (fieldContent === false) {
            return (
              <span>
                <IconEyeHidden className="" />
              </span>
            );
          } else if (fieldContent === true) {
            return (
              <span>
                <IconEye className="" />
              </span>
            );
          } else {
            return <span></span>;
          }

        case "IsProfileInitialized":
          if (fieldContent === true) {
            return <span className="admin-usertable__status admin-usertable__status--activated">{t("Activated")}</span>;
          } else {
            return <span className="admin-usertable__status admin-usertable__status--pending">{t("PendingActivation")}</span>;
          }

        case "deleteUser":
          return (
            <div className="admin-usertable__hidden">
              <Link to={"/user-profile/" + item.Id} target="_blank">
                <DeliwiButton className="button-style--secondary button-size--small">
                  <IconArrowForward />
                  {t("GoToTalentCard")}
                </DeliwiButton>
              </Link>
              <DeliwiButton onClick={() => openEditDialog(item)} className="button-style--secondary button-size--small">
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
              <DeliwiButton className="button-style--delete button-size--smallicon" onClick={() => onDeleteUser(item.Id)}>
                <IconTrash />
              </DeliwiButton>
            </div>
          );

        default:
          if (!fieldContent) return <span>-</span>;
          return <span>{fieldContent}</span>;
      }
    }
    return <span>-</span>;
  };

  const buildColumnsWithClickHandler = (): IColumn[] => {
    return [
      {
        key: "userName",
        name: t("UserName"),
        fieldName: "UserName",
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: true,
        onColumnClick,
      },
      {
        key: "department",
        name: t("Department"),
        fieldName: "Department",
        minWidth: 100,
        maxWidth: 300,
        isResizable: true,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: false,
        onColumnClick,
      },
      {
        key: "fillrate",
        name: t("ProfileFillRate"),
        fieldName: "FillRate",
        minWidth: 110,
        maxWidth: 110,
        isResizable: false,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: false,
        onColumnClick,
      },
      {
        key: "visibility",
        name: t("Visibility"),
        fieldName: "Visibility",
        minWidth: 80,
        maxWidth: 80,
        headerClassName: "text-align--center",
        className: "text-align--center",
        isResizable: false,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: false,
        onColumnClick,
      },
      {
        key: "permissions",
        name: t("AccessRight"),
        fieldName: "Permissions",
        minWidth: 100,
        maxWidth: 100,
        isResizable: false,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: false,
        onColumnClick,
      },
      {
        key: "IsProfileInitialized",
        name: t("ProfileStatus"),
        fieldName: "IsProfileInitialized",
        minWidth: 130,
        maxWidth: 130,
        headerClassName: "text-align--center",
        className: "text-align--center",
        isResizable: false,
        showSortIconWhenUnsorted: true,
        isSortedDescending: false,
        isSorted: false,
        onColumnClick,
      },
      {
        key: "deleteUser",
        name: "",
        minWidth: 1,
        maxWidth: 1,
        isResizable: true,
        className: "toolscolumn",
      },
    ];
  };

  return (
    <>
      <DetailsList items={sortedItems} className="admin-usertable" setKey="set" columns={columns} onRenderItemColumn={_renderItemColumn} ariaLabelForSelectionColumn={t("ToggleSelection")} ariaLabelForSelectAllCheckbox={t("ToggleSelectionAllTtems")} checkButtonAriaLabel={t("selectRow")} selectionMode={SelectionMode.multiple} onRenderRow={onRenderRow} checkboxVisibility={CheckboxVisibility.always} selection={selection} />

      <div className="admin-usertable__buttons">
        <DeliwiButton text={selectedItems.length > 1 ? t("SendEmails") : t("SendEmail")} onClick={handleSendEmails} disabled={selectedItems.length === 0} className="button-style--primary button-size--large" />

        <DeliwiButton text={t("EditUsers")} onClick={openEditDialogForSelectedUsers} disabled={selectedItems.length < 2} className="button-style--primary button-size--large" />

        <DeliwiButton text={selectedItems.length === 1 ? t("DeleteUsers") : t("DeleteUsers")} onClick={handleDeleteUsers} disabled={selectedItems.length < 2} className="button-style--delete button-size--large">
          <IconTrash />
        </DeliwiButton>
      </div>

      <Dialog hidden={!isDialogOpen} onDismiss={() => setIsDialogOpen(false)} className="admin-edituser admin-dialog" dialogContentProps={dialogContentProps}>
        <HelpDialog dialogContent={t("Help.EditUsers.Content")} title={t("Help.EditUsers.Title")} className="mb-16">
          <p className="text-style--Helper text-color--grey44">{t("SelectUserGroupsOrPeople")}</p>
        </HelpDialog>
        <ChoiceGroup
          label=""
          selectedKey={permissions.toString()}
          options={[
            { key: "1", text: "User" },
            { key: "2", text: "SuperUser" },
            { key: "10", text: "Admin" },
          ]}
          onChange={handlePermissionsChange}
        />
        <HelpDialog dialogContent={t("Help.SearchVisibility.Content")} title={t("Help.SearchVisibility.Title")} className="mb-16">
          <p className="text-style--Helper text-color--grey44">{t("SearchVisibility")}</p>
        </HelpDialog>
        <Toggle label="" checked={visibility} onText={t("Visible")} offText={t("Hidden")} onChange={handleVisibilityChange} />
        <DialogFooter>
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => setIsDialogOpen(false)} text={t("Cancel")}>
            <IconDismiss className="icon-size--16" />
          </DeliwiButton>
          <DeliwiButton className="button-style--primary button-size--large" onClick={handleSaveChanges} text={t("SaveChanges")} />
        </DialogFooter>
      </Dialog>
    </>
  );
};

const _copyAndSort = <
  T extends {
    FirstName?: string;
    LastName?: string;
    Email: string;
    [key: string]: any;
  },
>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean,
): T[] => {
  return items.slice().sort((a, b) => {
    let aValue: string, bValue: string;

    if (columnKey === "userName") {
      aValue = a.FirstName && a.LastName ? `${a.FirstName} ${a.LastName}` : a.Email;
      bValue = b.FirstName && b.LastName ? `${b.FirstName} ${b.LastName}` : b.Email;
    } else {
      aValue = a[columnKey] ?? "";
      bValue = b[columnKey] ?? "";
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return isSortedDescending ? bValue.localeCompare(aValue, undefined, { sensitivity: "base" }) : aValue.localeCompare(bValue, undefined, { sensitivity: "base" });
    }

    return isSortedDescending ? (aValue < bValue ? 1 : -1) : aValue > bValue ? 1 : -1;
  });
};
