import { FormEvent } from "react";
import { Checkbox, TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IUserProfile } from "interfaces/IUserProfile";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import "assets/styles/components/Forms.scss";
import deliwiRound from "assets/images/deliwi-round.svg";
import { IconCheckmark } from "components/Icons/IconCheckmark";

export interface IFirstVisitPersonalInformationEditFormProps {
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  JobTitle: string;
}

export interface IUserFirstVisitPersonalInformationEditForm {
  userProfileData: IUserProfile | undefined;

  userProfileSetter(data: IUserProfile): void;

  goToNextStep(): void;
}

export const UserFirstVisitPersonalInformationEdit = (props: IUserFirstVisitPersonalInformationEditForm) => {
  const shadowUserProfile = props.userProfileData;  
  const phoneRegExp = /^\+[0-9 ]{9,15}$/;

  const validationSchema = Yup.object({
    FirstName: Yup.string().required("Enter a valid First name"),
    LastName: Yup.string().required("Enter a valid Last name"),
    Email: Yup.string().email("Enter a valid email"),
    PhoneNumber: Yup.string().trim().matches(phoneRegExp, "Phone number is not valid"),
    Consent: Yup.boolean().oneOf([true], "Consent must be accepted."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirstName: shadowUserProfile?.FirstName || "",
      LastName: shadowUserProfile?.LastName || "",
      JobTitle: shadowUserProfile?.JobTitle || "",
      Email: shadowUserProfile?.Email || "",
      PhoneNumber: shadowUserProfile?.PhoneNumber || "",
      Consent: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
      props.goToNextStep();
    },
    validateOnMount: true,
  });

  const isFormDisabled = formik.isSubmitting || !formik.isValid || formik.isValidating;

  const handleCheckboxChange = (ev?: FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean) => {
    // Ensure that we have the event and the checked value is defined
    if (ev && checked !== undefined) {
      const { name } = ev.target as HTMLInputElement; // Cast the target to HTMLInputElement to access its name
      formik.setFieldValue(name, checked);
    }
  };

  const saveNewValues = (values: IFirstVisitPersonalInformationEditFormProps) => {
    const updatedProfile = {
      ...shadowUserProfile,
      FirstName: values.FirstName,
      LastName: values.LastName,
      JobTitle: values.JobTitle,
      Email: values.Email,
      PhoneNumber: values.PhoneNumber,
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-24">
          <div className="form--container--inner">
            <div className="form--container--header mb-32">
              <div className="logo-wrapper">
                <img src={deliwiRound} alt="DeliwiAI" className="icon-size-62 icon-shadow" />
              </div>
              <div className="text-style--Body1 fw-600">{t("PleaseCheckYourInformationBelow")}</div>
            </div>
            <h2 className="text-style--Body1 fw-600 mb-16">{t("PersonalInformation")}</h2>
            <div className="form--fields-container flex-wrap">
              <div className="form--field--wrapper col-6">
                <TextField label={t("FirstName")} id="FirstName" required={true} name="FirstName" defaultValue={formik.values.FirstName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>

              <div className="form--field--wrapper col-6">
                <TextField label={t("LastName")} id="LastName" required={true} name="LastName" defaultValue={formik.values.LastName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>

              <div className="form--field--wrapper col-6">
                <TextField label={t("JobTitle")} id="JobTitle" name="JobTitle" defaultValue={formik.values.JobTitle} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>

              <div className="form--field--wrapper col-6">
                <TextField label={t("PhoneNumber")} id="PhoneNumber" name="PhoneNumber" defaultValue={formik.values.PhoneNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {formik.errors.PhoneNumber && formik.touched.PhoneNumber ? <div className="input-error">{formik.errors.PhoneNumber}</div> : null}
              </div>

              <div className="form--field--wrapper col-12 mb-8">
                <TextField label="Email" id="Email" name="Email" required={true} defaultValue={formik.values.Email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                {formik.errors.Email && formik.touched.Email ? <div className="input-error">{formik.errors.Email}</div> : null}
              </div>

              <div className="form--field--wrapper col-12">
                <label className="field--label mb-8">{t("LabelPrivacyPolicy")}</label>
                <Checkbox label="Consent" id="Consent" name="Consent" required={true} checked={formik.values.Consent} onChange={handleCheckboxChange} onRenderLabel={_renderLabelWithLink} />
              </div>
            </div>
          </div>
        </div>
        <DeliwiButton className="button-style--primary button-size--large ml-auto" type="submit" disabled={isFormDisabled}>
          <IconCheckmark />
          {t("SaveContinue")}
        </DeliwiButton>
      </form>
    </>
  );
};

function _renderLabelWithLink() {
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: t("DescriptionPrivacyPolicy") }}></span>
    </>
  );
}
