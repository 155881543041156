import { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from "react";
import { searchSkills } from "services/apiService";
import "assets/styles/components/SingleSkillPicker.scss";

interface SingleSkillPickerProps {
  onSkillSelect: (skill: string) => void;
}

export const SingleSkillPicker = ({ onSkillSelect }: SingleSkillPickerProps) => {
  const [inputValue, setInputValue] = useState("");
  const [userTyping, setUserTyping] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const [showSuggestionsAbove, setShowSuggestionsAbove] = useState(false);

  useEffect(() => {
    if (suggestions.length > 0) {
      const inputRect = inputRef.current?.getBoundingClientRect() as DOMRect;
      const spaceBelow = (window.innerHeight - inputRect.bottom) as number;
      const neededSpace = 255 as number;
      setShowSuggestionsAbove(spaceBelow < neededSpace);
    }
  }, [suggestions.length]);

  useEffect(() => {
    if (!userTyping || inputValue.length <= 2) {
      setSuggestions([]);
      setHighlightedIndex(-1);
      return;
    }

    const fetchSkills = async () => {
      const fetchedSkills = await searchSkills(inputValue);
      // console.log(fetchedSkills);
      setSuggestions(fetchedSkills);
      setHighlightedIndex(fetchedSkills.length > 0 ? 0 : -1);
    };

    const timer = setTimeout(fetchSkills, 300); // Debounce to reduce API calls
    return () => clearTimeout(timer);
  }, [inputValue, userTyping]);

  useEffect(() => {
    // Correctly type the event parameter as a MouseEvent
    const handleClickOutside = (event: MouseEvent) => {
      // Make sure the target is an element for TypeScript type assertion
      const target = event.target as HTMLElement;

      // Check if the click is outside of both input and suggestions
      if (inputRef.current && !inputRef.current.contains(target) && suggestionsRef.current && !suggestionsRef.current.contains(target)) {
        setSuggestions([]); // Close suggestions
        setHighlightedIndex(-1);
        setShowSuggestionsAbove(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setUserTyping(true);

    // Pass the input value to the parent component only if it's longer than 2 characters
    if (newValue.length > 2) {
      onSkillSelect(newValue);
    } else {
      // Inform the parent component that there's no valid skill when the length is 2 or less
      onSkillSelect("");
    }
  };

  const handleSelectSkill = (skill: string) => {
    setInputValue(skill);
    onSkillSelect(skill);
    setSuggestions([]);
    setUserTyping(false);
    setShowSuggestionsAbove(false);
  };

  const handleClearInput = () => {
    setInputValue("");
    onSkillSelect("");
    setSuggestions([]);
    setHighlightedIndex(-1);
    setShowSuggestionsAbove(false);
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus back to the input
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (suggestions.length > 0) {
      if (event.key === "Escape") {
        handleSelectSkill(inputValue);
      } else if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        // Existing arrow key functionality
        setHighlightedIndex((prev) => {
          const newIndex = event.key === "ArrowDown" ? Math.min(prev + 1, suggestions.length - 1) : Math.max(prev - 1, 0);
          updateScroll(newIndex); // Update the scroll position
          return newIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelectSkill(suggestions[highlightedIndex]);
        } else if (inputValue.length > 2) {
          // Select the manually typed skill if it's longer than 2 characters
          handleSelectSkill(inputValue);
        }
      }
    } else if (event.key === "Enter" && inputValue.length > 2) {
      // Handle case where there are no suggestions but input value is valid
      handleSelectSkill(inputValue);
    }
  };

  const updateScroll = (index: number) => {
    const currentUl = suggestionsRef.current;
    if (currentUl && currentUl.children[index]) {
      const activeElement = currentUl.children[index] as HTMLElement;
      const ulRect = currentUl.getBoundingClientRect();
      const liRect = activeElement.getBoundingClientRect();

      if (liRect.bottom > ulRect.bottom) {
        currentUl.scrollTop += liRect.bottom - ulRect.bottom;
      } else if (liRect.top < ulRect.top) {
        currentUl.scrollTop -= ulRect.top - liRect.top;
      }
    }
  };

  return (
    <div className="skillpicker">
      <div className={`skillpicker__inputarea ${suggestions.length > 0 ? "suggestions-visible" : ""} ${showSuggestionsAbove ? "suggestions-above" : "suggestions-below"}`}>
        <label htmlFor="skillPicker" className="field-label">
          Skill to be analyzed *
        </label>
        <span>
          <input ref={inputRef} type="text" name="skillPicker" value={inputValue} placeholder="Start typing a skill ..." onChange={handleInputChange} onKeyDown={handleKeyDown} />
        </span>
        {inputValue && (
          <button onClick={handleClearInput} className="skillpicker__inputarea--button">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.89705 4.05379L3.96967 3.96967C4.23594 3.7034 4.6526 3.6792 4.94621 3.89705L5.03033 3.96967L10 8.939L14.9697 3.96967C15.2359 3.7034 15.6526 3.6792 15.9462 3.89705L16.0303 3.96967C16.2966 4.23594 16.3208 4.6526 16.1029 4.94621L16.0303 5.03033L11.061 10L16.0303 14.9697C16.2966 15.2359 16.3208 15.6526 16.1029 15.9462L16.0303 16.0303C15.7641 16.2966 15.3474 16.3208 15.0538 16.1029L14.9697 16.0303L10 11.061L5.03033 16.0303C4.76406 16.2966 4.3474 16.3208 4.05379 16.1029L3.96967 16.0303C3.7034 15.7641 3.6792 15.3474 3.89705 15.0538L3.96967 14.9697L8.939 10L3.96967 5.03033C3.7034 4.76406 3.6792 4.3474 3.89705 4.05379L3.96967 3.96967L3.89705 4.05379Z" fill="#424242" />
            </svg>
          </button>
        )}
      </div>
      {suggestions.length > 0 && (
        <ul ref={suggestionsRef} className="">
          {suggestions.map((skill, index) => (
            <li key={skill} onClick={() => handleSelectSkill(skill)} className={highlightedIndex === index ? "active" : ""}>
              {skill}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
