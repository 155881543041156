import { ReactNode, useState } from "react";

import { t } from "i18next";

import { IconButton, Dialog, DialogFooter } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import "assets/styles/components/HelpDialog.scss";
import { IconClose } from "components/Icons/IconClose";

interface HelpDialogProps {
  dialogContent: string;
  className?: string;
  title?: string;
  children: ReactNode;
}

export const HelpDialog = ({ children, dialogContent, className, title }: HelpDialogProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleToggleDialog = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const renderDialogContent = () => {
    return { __html: dialogContent };
  };

  return (
    <div className={`helpdialog ${className || ""}`}>
      <div className="helpdialog__maintext">{children}</div>

      <IconButton iconProps={{ iconName: "Info" }} onClick={handleToggleDialog} title={title || t("ShowExplanation")} />

      <Dialog
        hidden={!isDialogVisible}
        onDismiss={handleToggleDialog}
        dialogContentProps={{
          showCloseButton: true,
          title: title || t("HelpfulInformation"),
        }}
        modalProps={{
          className: "helpdialog__dialog",
        }}
      >
        <div className="helpdialog__dialog--content" dangerouslySetInnerHTML={renderDialogContent()}></div>
        <DialogFooter>
          <DeliwiButton onClick={handleToggleDialog} className="button-style--secondary button-size--medium">
            <IconClose className="icon-size--16" />
            {t("Close")}
          </DeliwiButton>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
