import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAdd = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6667 0.666667C10.6667 0.29848 10.3682 0 10 0C9.63181 0 9.33333 0.29848 9.33333 0.666667V9.33333H0.666667C0.29848 9.33333 0 9.63181 0 10C0 10.3682 0.29848 10.6667 0.666667 10.6667H9.33333V19.3333C9.33333 19.7015 9.63181 20 10 20C10.3682 20 10.6667 19.7015 10.6667 19.3333V10.6667H19.3333C19.7015 10.6667 20 10.3682 20 10C20 9.63181 19.7015 9.33333 19.3333 9.33333H10.6667V0.666667Z" fill="white" />
      </svg>
    </span>
  );
};
