import { useState } from "react";

import { t } from "i18next";

import { Modal, IconButton, IIconProps } from "@fluentui/react";

import IconDiamond from "assets/icons/icon-diamond.svg";
import IconUserQuestionmark from "assets/icons/icon-user-questionmark.svg";
import IconUserSearch from "assets/icons/icon-user-search.svg";
import { IconInfo } from "components/Icons/IconInfo";
import { IconClose } from "components/Icons/IconClose";
import "assets/styles/components/QuickTour.scss";
import { DeliwiButton } from "components/DeliwiButton";

const cancelIcon: IIconProps = { iconName: "Cancel" };

export const QuickTour = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleToggleDialog = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="quicktour">
      <DeliwiButton className="button-style--primary button-size--largeicon" onClick={handleToggleDialog}>
        <IconInfo />
      </DeliwiButton>

      <Modal isOpen={isModalOpen} onDismiss={handleToggleDialog} isBlocking={true} className="text-color--black" containerClassName="quicktour__modal">
        <div className="quicktour__modal--header mb-24">
          <div className="tag--candy">{t("How DeliwiAI works?")}</div>
          <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={handleToggleDialog} />
        </div>
        <h1 className="text-style--Large-title mb-16">{t("Write in your own words what kind of advice, insights or skilled resources you are looking for.")}</h1>
        <p className="text-style--Ingress text-color--brand10 fw-400 mb-40">{t("The DeliwiAI finds the people you need – whether you need help, a resource for a project or sparring. ")}</p>
        <h2 className="text-style--Title1 mb-16">{t("You can search for:")}</h2>
        <div className="quicktour__modal--columns mb-40">
          <div className="quicktour__modal--textcols">
            <div className="quicktour__modal--col">
              <img src={IconDiamond} className="icon-size-62 icon-shadow" alt="" />
              <h2 className="text-style--Title2 fw-600">{t("Specific skill")}</h2>
              <p className="text-style--Subtitle1 text-color--brand20">{t("Try to find someone with specific skill?")}</p>
              <p className="text-style--Body2 text-color--brand20">{t("Just tell to DeliwiAI what skill you are looking for.")}</p>
            </div>
            <div className="quicktour__modal--col">
              <img src={IconUserQuestionmark} className="icon-size-62 icon-shadow" alt="" />
              <h2 className="text-style--Title2 fw-600">{t("Mentor or advisor")}</h2>
              <p className="text-style--Subtitle1 text-color--brand20">{t("Try to find someone who has answer to your question or someone to spar with? ")}</p>
              <p className="text-style--Body2 text-color--brand20">{t("Just tell to DeliwiAI what kind of question or conversation topic do you have in mind.")}</p>
            </div>
            <div className="quicktour__modal--col">
              <img src={IconUserSearch} className="icon-size-62 icon-shadow" alt="" />
              <h2 className="text-style--Title2 fw-600">{t("Resource")}</h2>
              <p className="text-style--Subtitle1 text-color--brand20">{t("Do you need a specialist for your project or do you have a job position that needs to be filled?")}</p>
              <p className="text-style--Body2 text-color--brand20">{t("Describe the type of expertise you are looking for in your project.")}</p>
            </div>
          </div>
        </div>
        <DeliwiButton onClick={handleToggleDialog} className="button-style--secondary button-size--medium ml-auto">
          <IconClose className="icon-size--16" />
          {t("Close")}
        </DeliwiButton>
      </Modal>
    </div>
  );
};
