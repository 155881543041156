import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconEye = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.9842 8.62471L2.98322 8.62815C2.91226 8.8948 2.63854 9.05404 2.37177 8.9833C1.94073 8.86907 2.01662 8.37186 2.01662 8.37186L2.03429 8.31047C2.03429 8.31047 2.06039 8.22636 2.08142 8.1653C2.12344 8.04329 2.18771 7.87274 2.27906 7.66977C2.46129 7.26493 2.75419 6.72477 3.19852 6.18295C4.09605 5.08851 5.60485 4 7.99992 4C10.395 4 11.9038 5.08851 12.8013 6.18295C13.2457 6.72477 13.5385 7.26493 13.7208 7.66977C13.8121 7.87274 13.8764 8.04329 13.9184 8.1653C13.9395 8.22636 13.955 8.27541 13.9656 8.31047C13.9709 8.328 13.9749 8.34204 13.9778 8.35236L13.9814 8.365L13.9825 8.36916L13.9829 8.37069L13.9832 8.37186C14.054 8.63878 13.895 8.91253 13.6281 8.9833C13.3616 9.05397 13.0882 8.89556 13.0169 8.62937L13.0166 8.62815L13.0156 8.62471L13.0083 8.5997C13.0012 8.57616 12.9896 8.53927 12.9729 8.49095C12.9396 8.39422 12.8863 8.25227 12.8089 8.08023C12.6535 7.73508 12.4038 7.27523 12.0281 6.81706C11.2854 5.9115 10.0443 5 7.99992 5C5.95559 5 4.71439 5.9115 3.97176 6.81706C3.59603 7.27523 3.34631 7.73508 3.19094 8.08023C3.11351 8.25227 3.06023 8.39422 3.02691 8.49095C3.01027 8.53927 2.99866 8.57616 2.99155 8.5997L2.9842 8.62471ZM8 7C6.61929 7 5.5 8.11929 5.5 9.5C5.5 10.8807 6.61929 12 8 12C9.38071 12 10.5 10.8807 10.5 9.5C10.5 8.11929 9.38071 7 8 7ZM6.5 9.5C6.5 8.67157 7.17157 8 8 8C8.82843 8 9.5 8.67157 9.5 9.5C9.5 10.3284 8.82843 11 8 11C7.17157 11 6.5 10.3284 6.5 9.5Z" fill="#242424" />
      </svg>
    </span>
  );
};
