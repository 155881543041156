import "assets/styles/components/UsersListPagination.scss";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({ totalPages, currentPage, onPageChange }: PaginationProps) => {
  return (
    <div className="userlist__pagination">
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <button key={page} disabled={page === currentPage} onClick={() => onPageChange(page)}>
          {page}
        </button>
      ))}
    </div>
  );
};
