import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconControlPanel = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.74665 -0.00390625C7.67507 -0.00390625 5.99569 1.67548 5.99569 3.74711C5.99569 5.56203 7.28466 7.07589 8.99711 7.4232V9.49517H5.74821C4.506 9.49517 3.49898 10.5021 3.49898 11.7444V12.5695C1.78775 12.9178 0.5 14.4311 0.5 16.2451C0.5 18.3168 2.17939 19.9961 4.251 19.9961C6.32263 19.9961 8.00205 18.3168 8.00205 16.2451C8.00205 14.4294 6.71198 12.9151 4.99847 12.5686V11.7444C4.99847 11.3302 5.33414 10.9947 5.74821 10.9947H13.7455C14.1595 10.9947 14.4952 11.3302 14.4952 11.7444V12.5695C12.784 12.9178 11.4963 14.4311 11.4963 16.2451C11.4963 18.3168 13.1757 19.9961 15.2473 19.9961C17.3189 19.9961 18.9983 18.3168 18.9983 16.2451C18.9983 14.4294 17.7082 12.9151 15.9947 12.5686V11.7444C15.9947 10.5021 14.9877 9.49517 13.7455 9.49517H10.4966V7.42313C12.2089 7.07571 13.4977 5.56192 13.4977 3.74711C13.4977 1.67548 11.8183 -0.00390625 9.74665 -0.00390625ZM7.49518 3.74711C7.49518 2.50363 8.50318 1.49558 9.74665 1.49558C10.9901 1.49558 11.9982 2.50363 11.9982 3.74711C11.9982 4.99058 10.9901 5.99862 9.74665 5.99862C8.50318 5.99862 7.49518 4.99058 7.49518 3.74711ZM1.99949 16.2451C1.99949 15.0016 3.00753 13.9936 4.251 13.9936C5.49449 13.9936 6.50253 15.0016 6.50253 16.2451C6.50253 17.4885 5.49449 18.4966 4.251 18.4966C3.00753 18.4966 1.99949 17.4885 1.99949 16.2451ZM15.2473 13.9936C16.4908 13.9936 17.4988 15.0016 17.4988 16.2451C17.4988 17.4885 16.4908 18.4966 15.2473 18.4966C14.0038 18.4966 12.9957 17.4885 12.9957 16.2451C12.9957 15.0016 14.0038 13.9936 15.2473 13.9936Z" fill="black" />
      </svg>
    </span>
  );
};
