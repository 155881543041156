import { t } from "i18next";

import IconCurrentPlan from "assets/icons/icon-current-plan.svg";
import IconCompany from "assets/icons/icon-company.svg";
import "assets/styles/pages/ControlPanel.scss";

export const TabCompanyInfo = () => {
  return (
    <>
      <section className="admin-box mb-32">
        <h2 className="text-style--Subtitle1 text-color--brand10 mb-32">{t("Subscriber")}</h2>
        <div className="admin-box__iconset mb-32">
          <img src={IconCompany} className="icon-size-62 icon-shadow" alt="" />
          <div>
            <h3 className="text-style--Title3 text-style--semibold mb-16">Company name</h3>
            <p className="text-style--Body1">Company address</p>
          </div>
        </div>
        <div className="info-text">
          <p className="text-style--semibold mb-4">{t("Company info is inherited from Azure to the DeliwiAI.")}</p>
          <p className="text-style--semibold">{t("In order to make changes to the company information, you have to make changes to the Azure account.")}</p>
        </div>
      </section>
      <section className="admin-box">
        <h2 className="text-style--Subtitle1 text-color--brand10 mb-32">{t("Current DeliwiAI order plan")}</h2>
        <div className="currentplan mb-32">
          <div className="currentplan__details text-color--black">
            <div className="currentplan__details--price">
              <img src={IconCurrentPlan} className="icon-size-62 icon-shadow" alt="" />
              <p className="text-style--Title3 text-style--semibold">490{t(" $ / month")}</p>
            </div>
            <div className="currentplan__details--seats text-style--Body1 text-style--semibold">0-250 seats</div>
            <div className="currentplan__details--orderdate text-style--Body1 text-style--semibold">{t("Date of order:")} 12.12.2023</div>
          </div>
          <div className="currentplan__plan text-style--Title3 text-style--semibold text-color--white">{t("Current plan")}</div>
        </div>
        <div className="info-text">
          <p className="text-style--semibold mb-4">{t("The order information is retrieved from the azure market place.")}</p>
          <p className="text-style--semibold">{t("To change or cancel a subscription, you must contact an azure administrator.")}</p>
        </div>
      </section>
    </>
  );
};
