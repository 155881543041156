import { TextField } from "@fluentui/react";
import DatePicker from "react-datepicker";
import { DeliwiButton } from "components/DeliwiButton";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import * as Yup from "yup";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import { t } from "i18next";
import { format } from "date-fns";
import { useState } from "react";
import { IconAdd } from "../../Icons/IconAdd";
import { IconTrash } from "../../Icons/IconTrash";
import { IconDismiss } from "../../Icons/IconDismiss";
import { ConfirmDeleteDialog } from "../../ConfirmDeleteDialog";
import { IconEdit } from "../../Icons/IconEdit";
import { IconEditOff } from "../../Icons/IconEditOff";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IAwardsFormProps {
  Name: string;
  Issuer: string;
  Since: string;
}

export const UserAwardsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [newItemIndex, setNewItemIndex] = useState<number | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [itemNameToDelete, setItemNameToDelete] = useState<string | null>(null);
  const [itemTypeToDelete, setItemTypeToDelete] = useState<string | null>(null);
  const [itemTypeToDeletePlural, setItemTypeToDeletePlural] = useState<string | null>(null);

  const toggleGroupVisibility = (index: number | null) => {
    if (visibleIndex === null || visibleIndex !== index) {
      setVisibleIndex(index);
    } else {
      setVisibleIndex(null);
    }
  };

  const validationSchema = Yup.object().shape({
    awards: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().min(2, "Too short name").required("Award name is required"),
        Since: Yup.string().required("Award month and year is required"),
        Issuer: Yup.string().required("Awarding body is required"),
        // Rest of your amenities object properties
      }),
    ),
  });

  const saveNewValues = (values: IAwardsFormProps[]) => {
    const sortedValues = values.sort((a, b) => new Date(b.Since).valueOf() - new Date(a.Since).valueOf());
    const updatedProfile = {
      ...shadowUserProfile,
      Awards: sortedValues,
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
  };

  let awards = shadowUserProfile.Awards;

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("Awards")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          awards: awards,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveNewValues(values.awards);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, errors, isValid }) => (
          <Form>
            <div>
              <FieldArray
                name="awards"
                render={(arrayHelpers) => (
                  <div>
                    {values?.awards !== undefined && values.awards !== null && values?.awards.length > 0 && (
                      <div className="form--container large mb-16">
                        {values.awards.map((item, index) => {
                          const sinceDate = !!item.Since && new Date(item.Since);
                          const sinceDateFormatted = !!sinceDate ? format(sinceDate, "LLL yyyy") : "";
                          return (
                            <div className="form--fields-container item-row" key={index}>
                              <div className="form--fields--header">
                                <div className="item--preview-data">
                                  <p className="text-style--Body1 fw-600 text-color--brand80">{item.Name || ""}</p>
                                  <p className="text-style--Body1 fw-600 text-color--brand10">{item.Issuer}</p>
                                  {!!sinceDateFormatted && <p className="text-style--Body1 text-color--brand10 mb-16">{sinceDateFormatted}</p>}
                                </div>
                                {newItemIndex !== index && (
                                  <div className="form--item--actions">
                                    <DeliwiButton className={visibleIndex === index ? "button-style--outline button-size--medium bg-white active" : "button-style--outline button-size--medium bg-white"} onClick={() => toggleGroupVisibility(index)} disabled={!isValid}>
                                      {visibleIndex === index ? (
                                        <>
                                          <IconEditOff className="icon-size--16" />
                                          {t("CloseEdit")}
                                        </>
                                      ) : (
                                        <>
                                          <IconEdit className="icon-size--16" />
                                          {t("Edit")}
                                        </>
                                      )}
                                    </DeliwiButton>
                                    <DeliwiButton
                                      className="button-style--outline button-size--medium bg-white"
                                      onClick={() => {
                                        setIndexToDelete(index);
                                        setItemNameToDelete(item.Name);
                                        setItemTypeToDelete("Award");
                                        setItemTypeToDeletePlural("Awards");
                                        setConfirmDialogOpen(true);
                                      }}
                                    >
                                      <IconTrash className="icon-size--16" />
                                      {t("Delete")}
                                    </DeliwiButton>
                                  </div>
                                )}
                              </div>
                              {visibleIndex === index && (
                                <div className="form--fields-container-grid">
                                  <div className="form--field--wrapper">
                                    <TextField label={t("AwardName")} required={true} name={`awards.${index}.Name`} defaultValue={item.Name} onChange={handleChange} onBlur={handleBlur} />
                                    {(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Name && <div className="input-error">{(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Name}</div>}
                                  </div>
                                  <div className="form--field--wrapper">
                                    <TextField label={t("AwardingBody")} required={true} name={`awards.${index}.Issuer`} defaultValue={item.Issuer} onChange={handleChange} onBlur={handleBlur} />
                                    {(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Issuer && <div className="input-error">{(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Issuer}</div>}
                                  </div>

                                  <div className="form--field--wrapper">
                                    <label className="field-label">{t("AwardGranted")} *</label>
                                    <DatePicker
                                      selected={!!item.Since ? new Date(item.Since) : undefined}
                                      onChange={(date) => {
                                        if (date instanceof Date) {
                                          const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                          setFieldValue(`awards.${index}.Since`, dateValue);
                                        }
                                      }}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                      onBlur={() => setFieldTouched(`awards.${index}.Since`, true)}
                                    />
                                    {(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Since && <div className="input-error">{(errors?.awards as FormikErrors<IAwardsFormProps>[])?.[index]?.Since}</div>}
                                  </div>
                                </div>
                              )}
                              {newItemIndex === index && (
                                <div className="new-item--actions">
                                  <DeliwiButton
                                    className="button-style--secondary button-size--medium"
                                    onClick={() => {
                                      setNewItemIndex(null);
                                      arrayHelpers.remove(index);
                                      toggleGroupVisibility(null);
                                    }}
                                  >
                                    <IconDismiss className="icon-size--12" />
                                    {t("Cancel")}
                                  </DeliwiButton>
                                  <DeliwiButton
                                    className="button-style--primary button-size--medium"
                                    onClick={() => {
                                      setNewItemIndex(null);
                                      toggleGroupVisibility(index);
                                    }}
                                    disabled={!isValid}
                                  >
                                    <IconAdd className="icon-size--16" />
                                    {t("Add")}
                                  </DeliwiButton>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {confirmDialogOpen && (
                      <ConfirmDeleteDialog
                        itemName={itemNameToDelete}
                        itemType={itemTypeToDelete}
                        itemTypePlural={itemTypeToDeletePlural}
                        confirmDialogOpen={confirmDialogOpen}
                        setConfirmDialogOpen={setConfirmDialogOpen}
                        onConfirm={() => {
                          if (indexToDelete !== null) {
                            arrayHelpers.remove(indexToDelete);
                            handleSubmit();
                            toggleGroupVisibility(null);
                            setIndexToDelete(null);
                          }
                        }}
                      />
                    )}
                    <DeliwiButton
                      className="button-style--newitem mb-40 ml-24"
                      onClick={() => {
                        // NewIndexToOpen is length of the array before pushing a new item because first
                        // item key is 0
                        let newIndexToOpen;
                        if (values.awards === null) {
                          newIndexToOpen = 0;
                        } else {
                          newIndexToOpen = values.awards.length;
                        }
                        setNewItemIndex(newIndexToOpen);
                        arrayHelpers.push({ Name: "", Issuer: "", Since: "" });
                        toggleGroupVisibility(newIndexToOpen);
                      }}
                    >
                      <IconAdd />
                      {t("AddAward")}
                    </DeliwiButton>
                  </div>
                )}
              />
            </div>
            <div className="edit-dialog--buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
                <IconClose className="icon-size--20" />
                {t("Cancel")}
              </DeliwiButton>
              <DeliwiButton
                type="submit"
                className="button-style--primary button-size--large"
                onClick={() => {
                  handleSubmit();
                  props.hidePopup();
                }}
                disabled={props.saving}
              >
                <IconCheckmark />
                {t("SaveChanges")}
              </DeliwiButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
