import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconTetris = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25 0.9375C6.25 0.419733 6.66974 0 7.1875 0H11.25V5H6.25V0.9375ZM0.9375 6.25C0.419733 6.25 0 6.66974 0 7.1875V10.3125C0 10.8303 0.419733 11.25 0.9375 11.25H5V6.25H0.9375ZM11.25 6.25H6.25V11.25H10.3125C10.8303 11.25 11.25 10.8303 11.25 10.3125V6.25ZM16.5625 0H12.5V5H16.5625C17.0803 5 17.5 4.58026 17.5 4.0625V0.9375C17.5 0.419733 17.0803 0 16.5625 0ZM2.5 15.9375C2.5 15.4197 2.91974 15 3.4375 15H7.5V20H3.4375C2.91974 20 2.5 19.5803 2.5 19.0625V15.9375ZM13.75 20V15H8.75V20H13.75ZM20 15V19.0625C20 19.5803 19.5803 20 19.0625 20H15V15H20ZM15.9375 8.75C15.4197 8.75 15 9.16974 15 9.6875V13.75H20V9.6875C20 9.16974 19.5803 8.75 19.0625 8.75H15.9375Z" fill="#08757A" />
      </svg>
    </span>
  );
};
