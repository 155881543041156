import { useState } from "react";
import { t } from "i18next";

import { RunAnalysis } from "components/ControlPanel/RunAnalysis";
import { CreateSurvey } from "components/ControlPanel/CreateSurvey";
import { Reports } from "components/ControlPanel/Reports";
import { DeliwiButton } from "components/DeliwiButton";
import { HelpDialog } from "components/HelpDialog";

export const SkillAnalysisPanel = () => {
  const [activeComponent, setActiveComponent] = useState<string>("RunAnalysis"); // Specify the type as string

  const handleButtonClick = (componentName: string) => {
    setActiveComponent(componentName);
  };

  return (
    <div className="admin-box admin-analyses mb-40">
      <HelpDialog className="mb-32" dialogContent={t("Help.InvestigateYourOrganization.Content")} title={t("Help.InvestigateYourOrganization.Title")}>
        <h3 className="text-style--Subtitle1">{t("InvestigateYourOrganization")}</h3>
      </HelpDialog>
      <div className="admin-analyses__buttons mb-40">
        <DeliwiButton className={`${activeComponent === "RunAnalysis" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("RunAnalysis")}>
          {t("RunAnalysis")}
        </DeliwiButton>
        {/*<DeliwiButton className={`${activeComponent === "CreateSurvey" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("CreateSurvey")}>*/}
        {/*  {t("CreateSurvey")}*/}
        {/*</DeliwiButton>*/}
        {/*<DeliwiButton className={`${activeComponent === "Reports" ? "button-style--secondary button-size--medium" : "button-style--outline button-size--medium"}`} onClick={() => handleButtonClick("Reports")}>*/}
        {/*  {t("Reports")}*/}
        {/*</DeliwiButton>*/}
      </div>
      {activeComponent === "RunAnalysis" && <RunAnalysis />}
      {/*{activeComponent === "CreateSurvey" && <CreateSurvey />}*/}
      {/*{activeComponent === "Reports" && <Reports />}*/}
    </div>
  );
};
