import { useState, forwardRef, useImperativeHandle } from "react";
import { t } from "i18next";
import { ITag, TagPicker } from "@fluentui/react/lib/Pickers";
import { getUserProfilesDepartments } from "services/apiService";

interface DepartmentPickerProps {
  onDepartmentSelect: (selectedDepartment: string) => void; // Updated to accept a single string
}

export interface DepartmentPickerRef {
  clearSelection: () => void;
}

export const DepartmentPicker = forwardRef<DepartmentPickerRef, DepartmentPickerProps>(({ onDepartmentSelect }, ref) => {
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      setSelectedItems([]);
    },
  }));

  const onFilterChanged = async (filterText: string, selectedTags?: ITag[]): Promise<ITag[]> => {
    if (filterText.length < 2) {
      return [];
    }

    try {
      const departments = await getUserProfilesDepartments(filterText);
      const processedSelectedTags = selectedTags || [];
      return departments
        .map((departmentName) => ({
          key: departmentName,
          name: departmentName,
        }))
        .filter((tag) => !processedSelectedTags.some((selectedTag) => selectedTag.key === tag.key));
    } catch (error) {
      console.error("Error fetching departments:", error);
      return [];
    }
  };

  const onChange = (items?: ITag[]) => {
    if (items && items.length > 0) {
      // Since only one item can be selected, take the first item's name as the selected department
      const selectedDepartment = items[0].name;
      setSelectedItems([items[0]]);
      onDepartmentSelect(selectedDepartment);
    } else {
      // If no items are selected, clear the selection and invoke callback with empty string
      setSelectedItems([]);
      onDepartmentSelect("");
    }
  };

  return (
    <TagPicker
      onResolveSuggestions={onFilterChanged}
      getTextFromItem={(item: ITag) => item.name}
      pickerSuggestionsProps={{
        suggestionsHeaderText: t("SuggestedDepartments"),
        noResultsFoundText: t("NoResultsFound"),
        loadingText: t("Loading"),
      }}
      itemLimit={1} // Keep this to restrict the number of selectable items to one
      selectedItems={selectedItems}
      onChange={onChange}
      inputProps={{
        placeholder: t("TypeDepartmentNameToAnalyze"),
        "aria-label": "Department Picker",
      }}
    />
  );
});
