import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconAddUser = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.23529 0C5.63631 0 3.52941 2.10689 3.52941 4.70588C3.52941 7.30487 5.63631 9.41177 8.23529 9.41177C10.8343 9.41177 12.9412 7.30487 12.9412 4.70588C12.9412 2.10689 10.8343 0 8.23529 0ZM2.36321 10.5882C1.06253 10.5882 0 11.6316 0 12.9412C0 14.9308 0.979776 16.4309 2.51175 17.4078C4.01996 18.3694 6.05325 18.8235 8.23529 18.8235C8.71865 18.8235 9.19471 18.8013 9.65938 18.7561C8.76842 17.6474 8.23529 16.2389 8.23529 14.7059C8.23529 13.1416 8.79038 11.707 9.71431 10.5882H2.36321ZM14.7059 20C17.6298 20 20 17.6298 20 14.7059C20 11.782 17.6298 9.41177 14.7059 9.41177C11.782 9.41177 9.41177 11.782 9.41177 14.7059C9.41177 17.6298 11.782 20 14.7059 20ZM14.7059 11.7647C15.0307 11.7647 15.2941 12.0281 15.2941 12.3529V14.1176H17.0588C17.3836 14.1176 17.6471 14.3811 17.6471 14.7059C17.6471 15.0307 17.3836 15.2941 17.0588 15.2941H15.2941V17.0588C15.2941 17.3836 15.0307 17.6471 14.7059 17.6471C14.3811 17.6471 14.1176 17.3836 14.1176 17.0588V15.2941H12.3529C12.0281 15.2941 11.7647 15.0307 11.7647 14.7059C11.7647 14.3811 12.0281 14.1176 12.3529 14.1176H14.1176V12.3529C14.1176 12.0281 14.3811 11.7647 14.7059 11.7647Z" fill="#08757A" />
      </svg>
    </span>
  );
};
