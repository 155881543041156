import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InitialSetup } from "interfaces/InitialSetup";
import { getInitialSetupStatus } from "services/apiService";
import { InstallDeliwi } from "components/InstallDeliwi";

export const Setup = () => {
  const [setupStatus, setSetupStatus] = useState<InitialSetup | null>(null);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const navigate = useNavigate();

  const isSetupIncomplete = (status: InitialSetup) => {
    // If AuthorizationGroupsCreated is true, ignore the contents of AuthorizationGroups
    if (status.AuthorizationGroupsCreated) {
      return Object.entries(status).some(([key, value]) => {
        // Skip the AuthorizationGroups check
        if (key === "AuthorizationGroups") return false;
        // Since 'value' can be a boolean, handle this case
        return value === false;
      });
    } else {
      // Original logic if AuthorizationGroupsCreated is not true
      return Object.values(status).some((value) => {
        // Since 'value' can be a boolean or an object, handle both cases
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((innerValue) => innerValue === "");
        } else {
          return value === false;
        }
      });
    }
  };

  // Fetch setup status on component mount
  useEffect(() => {
    const fetchSetupStatus = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await getInitialSetupStatus();
        // const response = {
        //   ConfigurationExist: false,
        //   TeamsBotEnabled: false,
        //   AuthorizationGroupsCreated: false,
        //   AdminsAdded: false,
        //   AuthorizationGroups: {
        //     Admins: "",
        //     SuperUsers: "",
        //     Users: "",
        //   },
        // };
        // console.log(response);
        setSetupStatus(response);
      } catch (error) {
        console.error("Error fetching setup status:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchSetupStatus();
  }, []);
  useEffect(() => {
    if (!isLoading && setupStatus && !isSetupIncomplete(setupStatus)) {
      navigate("/"); // Redirect to root if setup is complete
    }
  }, [isLoading, setupStatus, navigate]);

  if (isLoading) {
    return (
      <div className="load-wrapper">
        <div className="deliwi-spinner"></div>
      </div>
    );
  }
  return setupStatus && isSetupIncomplete(setupStatus) ? <InstallDeliwi /> : null;
};
