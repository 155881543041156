import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconEditOff = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon">
          <path id="Shape" d="M2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L7.54304 8.25015L3.54545 12.2475C3.21763 12.5754 2.99008 12.9899 2.88953 13.4424L2.01191 17.3923C1.97483 17.5592 2.02559 17.7335 2.14649 17.8544C2.26739 17.9753 2.44166 18.026 2.60855 17.9889L6.53494 17.1157C7.00237 17.0118 7.43048 16.7767 7.76907 16.4381L11.75 12.4571L17.1464 17.8536C17.3417 18.0488 17.6583 18.0488 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1464L2.85355 2.14645ZM11.0429 11.75L7.06196 15.731C6.85781 15.9352 6.59969 16.0769 6.31786 16.1396L3.15855 16.8421L3.86572 13.6593C3.92502 13.3924 4.05921 13.148 4.25254 12.9547L8.25015 8.95725L11.0429 11.75ZM15.0416 7.7513L12.457 10.3359L13.1641 11.043L17.1271 7.08003C18.2681 5.939 18.2913 4.09631 17.1794 2.92689C16.0271 1.71505 14.1027 1.69077 12.9203 2.87317L8.95717 6.83608L9.66428 7.54319L12.2489 4.95864L15.0416 7.7513ZM13.6274 3.58029C14.4123 2.79538 15.6898 2.8115 16.4547 3.61596C17.1928 4.39225 17.1774 5.61548 16.42 6.37293L15.7487 7.04418L12.9561 4.25155L13.6274 3.58029Z" fill="white" />
        </g>
      </svg>
    </span>
  );
};
