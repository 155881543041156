import { IUserProfile } from "../interfaces/IUserProfile";
import { UserProfileDetails } from "./UserProfile/UserProfileDetails";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { UserProfilePreviewOverview } from "./UserProfile/UserProfilePreviewOverview";
import { t } from "i18next";
import "assets/styles/components/UserProfilePreview.scss";
import { useEffect, useState } from "react";
import { getUserAvailability } from "../services/apiService";

export interface IUserProfilePreviewProps {
  userProfile: IUserProfile;

  closePreview(): void;
}

export const UserProfilePreview = (props: IUserProfilePreviewProps) => {
  const userProfile = props.userProfile;
  const id = userProfile.id;
  const [userAvailability, setUserAvailability] = useState<string>("Unknown");

  useEffect(() => {
    (async () => {
      if (id !== undefined) {
        const userAvailability = await getUserAvailability(id);
        if (!!userAvailability?.availability) {
          setUserAvailability(userAvailability.availability);
        }
      }
    })();
  }, [id]);

  return (
    <div className="profile-search--profile-preview--container">
      <div className="profile-preview--header">
        <h3 className="text-style--Title2">{t("TalentCardPreviewLabel")}</h3>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-preview" onClick={() => props.closePreview()}></ChromeCloseIcon>
        </div>
      </div>
      {userProfile !== undefined ? (
        <div className="user-profile-page">
          <div className="user-profile-page--container preview">
            <div className="user-profile-page--overview">
              <UserProfilePreviewOverview userProfile={userProfile} userAvailability={userAvailability} />
            </div>
            <div className="user-profile-page--details">
              <UserProfileDetails userProfile={userProfile} displayMode="preview" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
