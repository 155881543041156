import { IUserProfileTab } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { Label } from "@fluentui/react";
import { format } from "date-fns";
import { DeliwiButton } from "components/DeliwiButton";
import { IconEdit } from "../../Icons/IconEdit";
import { ReadMoreWeb } from "react-shorten";
import { IconAddSmall } from "../../Icons/IconAddSmall";
import textWithLineBreaks from "functions/TextWithLineBreaks";
import { useUserRole } from "contexts/UserRoleContext";
import { IconContactCard } from "../../Icons/IconContactCard";
import { IconPersonAccounts } from "../../Icons/IconPersonAccounts";

export const TabWorkExperience = (props: IUserProfileTab) => {
  const shadowUserProfile = props.userProfileData;
  const canEdit = props.canEdit;
  const displayMode = props.displayMode;
  const { userRole } = useUserRole();
  const canView = userRole.includes("admin") || userRole.includes("superuser");

  let workHistory = shadowUserProfile.WorkExperience;

  if (!!workHistory && !canView) {
    workHistory = workHistory.slice(0, 3);
  }

  return (
    <>
      {displayMode !== "preview" && (
        <div className="user-profile-tab--heading--container mb-40 ml-24">
          <IconPersonAccounts />
          <h2 className="user-profile-tab--heading text-style--Title2 text-style--semibold">{t("WorkExperience")}</h2>
        </div>
      )}

      {displayMode !== "preview" && canView && (
        <div className="field-group--container">
          <div className="field-group--header">
            <h3 className="text-style--Subtitle1 text-color--brand10">{t("CurrentWork")}</h3>
            {canEdit && (
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="current-work"
                onClick={() => {
                  props.setFieldGroupToEdit("current-work");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            )}
          </div>
          {!!shadowUserProfile?.JobDescription ? (
            <>
              <div className="field-group--item">
                <div className="field-group--item--wrapper">
                  <h4 className="field-label mb-8">{t("JobDescription")}</h4>
                  <div className="long-text--container">
                    <div className="text-style--Body2">
                      <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                        {textWithLineBreaks(shadowUserProfile?.JobDescription || "")}
                      </ReadMoreWeb>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : canEdit ? (
            <>
              <div className="empty-content-placeholder">
                <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.CurrentWork.Title")}</h3>
                <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.CurrentWork.Content")}</p>
                <DeliwiButton
                  onClick={() => {
                    props.setFieldGroupToEdit("current-work");
                    props.showPopup();
                  }}
                  className="button-style--candy button-size--large"
                >
                  <IconAddSmall className="icon-size--10" />
                  {t("UserProfile.Empty.CurrentWork.Button")}
                </DeliwiButton>
              </div>
            </>
          ) : null}
        </div>
      )}

      {canView && (
        <div className="field-group--container">
          <div className="field-group--header">
            <h3 className="text-style--Subtitle1 text-color--brand10">{t("RecentProjects")}</h3>
            {canEdit && (
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="recent-project"
                onClick={() => {
                  props.setFieldGroupToEdit("recent-projects");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            )}
          </div>
          {!!shadowUserProfile?.RecentProjects && shadowUserProfile.RecentProjects.length > 0 ? (
            <>
              {shadowUserProfile?.RecentProjects?.map((project, index) => {
                const sinceDate = new Date(project.Since);
                const sinceDateFormatted = format(sinceDate, "LLL yyyy");
                const untilDate = !!project.Until && new Date(project.Until);
                const untilDateFormatted = !!untilDate ? format(untilDate, "LLL yyyy") : "Present";
                return (
                  <div className="field-group--item item-listing" key={index}>
                    <div className="field-group--item--wrapper">
                      <span className="text-style--field-display-label">{project.Name}</span>
                    </div>

                    <div className="field-group--item--wrapper">
                      <span className="text-style--Body1 fw-600">{project.Role}</span>
                    </div>

                    <div className="field-group--item--wrapper">
                      <span className="text-style--Body2">
                        {sinceDateFormatted} - {untilDateFormatted}
                      </span>
                    </div>
                    {!!project?.Description && (
                      <div className="field-group--item--wrapper item-description">
                        <div className="long-text--container">
                          <div className="text-style--Body2">
                            <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                              {textWithLineBreaks(project.Description)}
                            </ReadMoreWeb>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : displayMode !== "preview" ? (
            <>
              <div className="empty-content-placeholder">
                <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Projects.Title")}</h3>
                <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Projects.Content")}</p>
                <DeliwiButton
                  onClick={() => {
                    props.setFieldGroupToEdit("recent-projects");
                    props.showPopup();
                  }}
                  className="button-style--candy button-size--large"
                >
                  <IconAddSmall className="icon-size--10" />
                  {t("UserProfile.Empty.Projects.Button")}
                </DeliwiButton>
              </div>
            </>
          ) : null}
        </div>
      )}

      {canView && (
        <div className="field-group--container">
          <div className="field-group--header">
            <h3 className="text-style--Subtitle1 text-color--brand10">{t("MyCareer")}</h3>
            {canEdit && (
              <DeliwiButton
                className="button-style--outline button-size--medium"
                value="my-career"
                onClick={() => {
                  props.setFieldGroupToEdit("my-career");
                  props.showPopup();
                }}
              >
                <IconEdit />
                {t("Edit")}
              </DeliwiButton>
            )}
          </div>
          {!!shadowUserProfile?.WorkBio ? (
            <>
              <div className="field-group--item">
                <div className="field-group--item--wrapper">
                  <Label className="field-label">{t("ShortCareerSummary")}</Label>
                  <div className="long-text--container">
                    <div className="text-style--Body2">
                      <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                        {textWithLineBreaks(shadowUserProfile?.WorkBio || "")}
                      </ReadMoreWeb>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : displayMode !== "preview" && canEdit ? (
            <>
              <div className="empty-content-placeholder">
                <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.Career.Title")}</h3>
                <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.Career.Content")}</p>
                <DeliwiButton
                  onClick={() => {
                    props.setFieldGroupToEdit("my-career");
                    props.showPopup();
                  }}
                  className="button-style--candy button-size--large"
                >
                  <IconAddSmall className="icon-size--10" />
                  {t("UserProfile.Empty.Career.Button")}
                </DeliwiButton>
              </div>
            </>
          ) : null}
        </div>
      )}

      <div className="field-group--container">
        <div className="field-group--header">
          <h3 className="text-style--Subtitle1 text-color--brand10">{t("WorkExperience")}</h3>
          {canEdit && (
            <DeliwiButton
              className="button-style--outline button-size--medium"
              value="work-history"
              onClick={() => {
                props.setFieldGroupToEdit("work-history");
                props.showPopup();
              }}
            >
              <IconEdit />
              {t("Edit")}
            </DeliwiButton>
          )}
        </div>
        {!!workHistory && workHistory.length > 0 ? (
          <>
            {workHistory.map((workExperience, index) => {
              const sinceDate = new Date(workExperience.Since);
              const sinceDateFormatted = format(sinceDate, "LLL yyyy");
              const untilDate = !!workExperience.Until && new Date(workExperience.Until);
              const untilDateFormatted = !!untilDate ? format(untilDate, "LLL yyyy") : "Present";
              return (
                <div className="field-group--item item-listing" key={index}>
                  <div className="field-group--item--wrapper">
                    <span className="text-style--field-display-label">{workExperience.Organization}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body1 fw-600">{workExperience.Title}</span>
                  </div>

                  <div className="field-group--item--wrapper">
                    <span className="text-style--Body2">
                      {sinceDateFormatted} - {untilDateFormatted}
                    </span>
                  </div>
                  {!!workExperience?.Description && (
                    <div className="field-group--item--wrapper item-description">
                      <div className="long-text--container">
                        <div className="text-style--Body2">
                          <ReadMoreWeb truncate={400} showMoreText={t("ReadMore")} showLessText={t("ReadLess")} className="read-more-btn">
                            {textWithLineBreaks(workExperience.Description)}
                          </ReadMoreWeb>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : displayMode !== "preview" && canEdit ? (
          <>
            <div className="empty-content-placeholder">
              <h3 className="text-style--Subtitle1 mb-16">{t("UserProfile.Empty.WorkExperience.Title")}</h3>
              <p className="text-style--Body2 text-color--brand10 mb-16 empty-content-placeholder--description">{t("UserProfile.Empty.WorkExperience.Content")}</p>
              <DeliwiButton
                onClick={() => {
                  props.setFieldGroupToEdit("work-history");
                  props.showPopup();
                }}
                className="button-style--candy button-size--large"
              >
                <IconAddSmall className="icon-size--10" />
                {t("UserProfile.Empty.WorkExperience.Button")}
              </DeliwiButton>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
