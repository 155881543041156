import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconOffline = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Shape" d="M19.1898 8.80999C19.7366 9.35672 19.7366 10.2431 19.1898 10.7899L15.9798 13.9999L19.1898 17.2099C19.7366 17.7567 19.7366 18.6431 19.1898 19.1898C18.6431 19.7366 17.7567 19.7366 17.2099 19.1898L13.9999 15.9798L10.7899 19.1898C10.2431 19.7366 9.35672 19.7366 8.80999 19.1898C8.26326 18.6431 8.26326 17.7567 8.80999 17.2099L12.02 13.9999L8.80999 10.7899C8.26326 10.2431 8.26326 9.35672 8.80999 8.80999C9.35672 8.26326 10.2431 8.26326 10.7899 8.80999L13.9999 12.02L17.2099 8.80999C17.7567 8.26326 18.6431 8.26326 19.1898 8.80999ZM0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14ZM14 2.79998C7.8144 2.79998 2.79998 7.8144 2.79998 14C2.79998 20.1856 7.8144 25.2 14 25.2C20.1856 25.2 25.2 20.1856 25.2 14C25.2 7.8144 20.1856 2.79998 14 2.79998Z" fill="#616161" />
      </svg>
    </span>
  );
};
