import { TextField } from "@fluentui/react";
import DatePicker from "react-datepicker";
import { DeliwiButton } from "components/DeliwiButton";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import * as Yup from "yup";
import { FieldArray, Form, Formik, FormikErrors } from "formik";
import { t } from "i18next";
import { format } from "date-fns";
import { useState } from "react";
import { IconAdd } from "../../Icons/IconAdd";
import { IconTrash } from "../../Icons/IconTrash";
import { IconDismiss } from "../../Icons/IconDismiss";
import { ConfirmDeleteDialog } from "../../ConfirmDeleteDialog";
import { IconEditOff } from "../../Icons/IconEditOff";
import { IconEdit } from "../../Icons/IconEdit";
import "react-datepicker/dist/react-datepicker.css";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IEducationFormProps {
  Organization: string;
  Title: string;
  Since: string;
  Until: string;
  Description: string;
}

export const UserEducationEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [newItemIndex, setNewItemIndex] = useState<number | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [indexToDelete, setIndexToDelete] = useState<number | null>(null);
  const [itemNameToDelete, setItemNameToDelete] = useState<string | null>(null);
  const [itemTypeToDelete, setItemTypeToDelete] = useState<string | null>(null);
  const [itemTypeToDeletePlural, setItemTypeToDeletePlural] = useState<string | null>(null);

  const toggleGroupVisibility = (index: number | null) => {
    if (visibleIndex === null || visibleIndex !== index) {
      setVisibleIndex(index);
    } else {
      setVisibleIndex(null);
    }
  };

  const validationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        Organization: Yup.string().min(2, "Too short name").required("Education organization is required"),
        Title: Yup.string().min(2, "Too short name").required("Education degree or programme is required"),
        Since: Yup.string().required("Education start date is required"),
        Until: Yup.string().required("Education end date is required"),
        Description: Yup.string().required("Field of study is required"),
        // Rest of your amenities object properties
      }),
    ),
  });

  const saveNewValues = (values: IEducationFormProps[]) => {
    const sortedValues = values.sort((a, b) => new Date(b.Since).valueOf() - new Date(a.Since).valueOf());
    const updatedProfile = {
      ...shadowUserProfile,
      Education: sortedValues,
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
  };

  let education = shadowUserProfile.Education;

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("Education")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          education: education,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveNewValues(values.education);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid, errors }) => (
          <Form>
            <div>
              <FieldArray
                name="education"
                render={(arrayHelpers) => (
                  <div>
                    {values?.education !== undefined && values.education !== null && values?.education.length > 0 && (
                      <div className="form--container large mb-16">
                        {values.education.map((item, index) => {
                          const sinceDate = !!item.Since && new Date(item.Since);
                          const sinceDateFormatted = !!sinceDate ? format(sinceDate, "LLL yyyy") : "";
                          const untilDate = !!item.Until && new Date(item.Until);
                          const untilDateFormatted = !!untilDate ? format(untilDate, "LLL yyyy") : !!sinceDateFormatted && !untilDate ? "Present" : "";
                          return (
                            <div className="form--fields-container item-row" key={index}>
                              <div className="form--fields--header">
                                <div className="item--preview-data">
                                  <p className="text-style--Body1 fw-600 text-color--brand80">{item.Organization || ""}</p>
                                  <p className="text-style--Body1 fw-600 text-color--brand10">{item.Title}</p>
                                  {!!sinceDateFormatted && !!untilDateFormatted && (
                                    <p className="text-style--Body1 text-color--brand10 mb-16">
                                      {sinceDateFormatted} - {untilDateFormatted}
                                    </p>
                                  )}
                                  <p className="line-clamp-2 text-style--Body1 text-color--brand10">{item.Description}</p>
                                </div>
                                {newItemIndex !== index && (
                                  <div className="form--item--actions">
                                    <DeliwiButton className={visibleIndex === index ? "button-style--outline button-size--medium bg-white active" : "button-style--outline button-size--medium bg-white"} onClick={() => toggleGroupVisibility(index)} disabled={!isValid}>
                                      {visibleIndex === index ? (
                                        <>
                                          <IconEditOff className="icon-size--16" />
                                          {t("CloseEdit")}
                                        </>
                                      ) : (
                                        <>
                                          <IconEdit className="icon-size--16" />
                                          {t("Edit")}
                                        </>
                                      )}
                                    </DeliwiButton>
                                    <DeliwiButton
                                      className="button-style--outline button-size--medium bg-white"
                                      onClick={() => {
                                        setIndexToDelete(index);
                                        setItemNameToDelete(item.Organization);
                                        setItemTypeToDelete("Education");
                                        setItemTypeToDeletePlural("Educations");
                                        setConfirmDialogOpen(true);
                                      }}
                                    >
                                      <IconTrash className="icon-size--16" />
                                      {t("Delete")}
                                    </DeliwiButton>
                                  </div>
                                )}
                              </div>
                              {visibleIndex === index && (
                                <div className="form--fields-container-grid">
                                  <div className="form--field--wrapper">
                                    <TextField label={t("SchoolName")} required={true} name={`education.${index}.Organization`} defaultValue={item.Organization} onChange={handleChange} onBlur={handleBlur} />
                                    {(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Organization && <div className="input-error">{(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Organization}</div>}
                                  </div>
                                  <div className="form--field--wrapper">
                                    <TextField label={t("DegreeOrProgramme")} required={true} placeholder={t("Ex: Bachelor’s / Ex: Business")} name={`education.${index}.Title`} defaultValue={item.Title} onChange={handleChange} onBlur={handleBlur} />
                                    {(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Title && <div className="input-error">{(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Title}</div>}
                                  </div>

                                  <div className="form--field--wrapper">
                                    <label className="field-label">{t("StartDate")} *</label>
                                    <DatePicker
                                      selected={!!item.Since ? new Date(item.Since) : undefined}
                                      onChange={(date) => {
                                        if (date instanceof Date) {
                                          const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                          setFieldValue(`education.${index}.Since`, dateValue);
                                        }
                                      }}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                      onBlur={() => setFieldTouched(`education.${index}.Since`, true)}
                                    />
                                    {(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Since && <div className="input-error">{(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Since}</div>}
                                  </div>
                                  <div className="form--field--wrapper">
                                    <label className="field-label">{t("EndDateOrExpected")} *</label>
                                    <DatePicker
                                      selected={!!item.Until ? new Date(item.Until) : undefined}
                                      onChange={(date) => {
                                        if (date instanceof Date) {
                                          const dateValue = format(date, "yyyy-MM-dd'T'HH:mm:ss");
                                          setFieldValue(`education.${index}.Until`, dateValue);
                                        }
                                      }}
                                      dateFormat="MM/yyyy"
                                      showMonthYearPicker
                                      onBlur={() => setFieldTouched(`education.${index}.Until`, true)}
                                    />
                                    {(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Until && <div className="input-error">{(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Until}</div>}
                                  </div>

                                  <div className="form--field--wrapper full-width mb-24">
                                    <TextField label={t("FieldOfStudy")} required={true} name={`education.${index}.Description`} defaultValue={item.Description} onChange={handleChange} onBlur={handleBlur} />
                                    {(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Description && <div className="input-error">{(errors?.education as FormikErrors<IEducationFormProps>[])?.[index]?.Description}</div>}
                                  </div>
                                </div>
                              )}
                              {newItemIndex === index && (
                                <div className="new-item--actions">
                                  <DeliwiButton
                                    className="button-style--secondary button-size--medium"
                                    onClick={() => {
                                      setNewItemIndex(null);
                                      arrayHelpers.remove(index);
                                      toggleGroupVisibility(null);
                                    }}
                                  >
                                    <IconDismiss className="icon-size--12" />
                                    {t("Cancel")}
                                  </DeliwiButton>
                                  <DeliwiButton
                                    className="button-style--primary button-size--medium"
                                    onClick={() => {
                                      setNewItemIndex(null);
                                      toggleGroupVisibility(index);
                                    }}
                                    disabled={!isValid}
                                  >
                                    <IconAdd className="icon-size--16" />
                                    {t("Add")}
                                  </DeliwiButton>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {confirmDialogOpen && (
                      <ConfirmDeleteDialog
                        itemName={itemNameToDelete}
                        itemType={itemTypeToDelete}
                        itemTypePlural={itemTypeToDeletePlural}
                        confirmDialogOpen={confirmDialogOpen}
                        setConfirmDialogOpen={setConfirmDialogOpen}
                        onConfirm={() => {
                          if (indexToDelete !== null) {
                            arrayHelpers.remove(indexToDelete);
                            handleSubmit();
                            toggleGroupVisibility(null);
                            setIndexToDelete(null);
                          }
                        }}
                      />
                    )}{" "}
                    <DeliwiButton
                      className="button-style--newitem"
                      onClick={() => {
                        // NewIndexToOpen is length of the array before pushing a new item because first
                        // item key is 0
                        let newIndexToOpen;
                        if (values.education === null) {
                          newIndexToOpen = 0;
                        } else {
                          newIndexToOpen = values.education.length;
                        }
                        setNewItemIndex(newIndexToOpen);
                        arrayHelpers.push({ Organization: "", Title: "", Since: "", Until: null, Description: "" });
                        toggleGroupVisibility(newIndexToOpen);
                      }}
                    >
                      <IconAdd />
                      {t("AddEducation")}
                    </DeliwiButton>
                  </div>
                )}
              />
            </div>
            <div className="edit-dialog--buttons">
              <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
                <IconClose className="icon-size--20" />
                {t("Cancel")}
              </DeliwiButton>
              <DeliwiButton
                type="submit"
                className="button-style--primary button-size--large"
                onClick={() => {
                  handleSubmit();
                  props.hidePopup();
                }}
                disabled={props.saving}
              >
                <IconCheckmark />
                {t("SaveChanges")}
              </DeliwiButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
