import { useState, useRef, useCallback, FormEvent } from "react";
import { useMsal } from "@azure/msal-react";

import { t } from "i18next";

import { Stack, TextField, Icon, Dialog, DialogFooter } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IPersonaProps } from "@fluentui/react/lib/Persona";
import { PeoplePicker, PeoplePickerRef } from "components/ControlPanel/PeoplePicker";

import { HelpDialog } from "components/HelpDialog";

import { InitialSetup } from "interfaces/InitialSetup";
import { getInitialSetupStatus, postInitialSetupConfigurations, postInitialSetupGroups, postInitialSetupAdmins } from "services/apiService";

import deliwiLogoRound from "assets/images/deliwi-round.svg";
import stepIcon1 from "assets/icons/icon-step-1.svg";
import stepIcon2 from "assets/icons/icon-step-2.svg";
import stepIcon3 from "assets/icons/icon-step-3.svg";
import "assets/styles/components/InstallDeliwi.scss";

interface TextFieldValues {
  Admins: string;
  SuperUsers: string;
  Users: string;
}

export const InstallDeliwi = () => {
  const [isLoadingToggleTeams, setIsLoadingToggleTeams] = useState(false);
  const [isLoadingCreateGroups, setIsLoadingCreateGroups] = useState(false);
  const [isLoadingSetAdmins, setIsLoadingSetAdmins] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalClosed, setIsModalClosed] = useState(false);
  const [setupStatus, setSetupStatus] = useState<InitialSetup>({
    ConfigurationExist: false,
    TeamsBotEnabled: false,
    AuthorizationGroupsCreated: false,
    AdminsAdded: false,
    AuthorizationGroups: {
      Admins: "",
      SuperUsers: "",
      Users: "",
    },
  });

  const { instance } = useMsal();

  const [selectedAdminUsers, setSelectedAdminUsers] = useState<string[]>([]);
  const peoplePickerRef = useRef<PeoplePickerRef>(null);

  const [textFieldValues, setTextFieldValues] = useState<TextFieldValues>({
    Admins: "deliwiai_admins",
    SuperUsers: "deliwiai_superusers",
    Users: "deliwiai_users",
  });

  const handleInputChange = useCallback((event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    const target = event.target as HTMLInputElement;
    const inputValue = newValue ?? target.value;
    const cleanedValue = cleanInputValue(inputValue);
    setTextFieldValues((prevValues) => ({ ...prevValues, [target.name]: cleanedValue }));
  }, []);

  const cleanInputValue = (inputValue: string): string => {
    // Allow uppercase and lowercase letters, numbers, spaces, hyphens, and underscores
    const onlyValidChars = inputValue.replace(/[^a-zA-Z0-9\s-_]/g, "");
    return onlyValidChars.slice(0, 256);
  };

  const handleUserSelection = (users: IPersonaProps[]) => {
    const selectedIds = users.map((user) => user.key as string);
    setSelectedAdminUsers(selectedIds);
  };

  const handleToggleTeams = async () => {
    setIsLoadingToggleTeams(true);
    const delayPromise = new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      const data = {
        InviteMessageTemplate: "Custom invite message template",
        TeamsBotEnabled: true,
      };
      await Promise.all([delayPromise, postInitialSetupConfigurations(data)]);
      const updatedSetup = await getInitialSetupStatus();
      // const updatedSetup = {
      //   ConfigurationExist: true,
      //   TeamsBotEnabled: true,
      //   AuthorizationGroupsCreated: false,
      //   AdminsAdded: false,
      //   AuthorizationGroups: {
      //     Admins: "",
      //     SuperUsers: "",
      //     Users: "",
      //   },
      // };
      setSetupStatus(updatedSetup);
    } catch (error) {
      console.error("Error updating Teams bot status:", error);
    } finally {
      setIsLoadingToggleTeams(false);
      // checkAndCompleteSetup();
    }
  };

  const handleCreateGroups = async () => {
    setIsLoadingCreateGroups(true);
    const delayPromise = new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      await Promise.all([delayPromise, postInitialSetupGroups(textFieldValues)]);
      const updatedSetup = await getInitialSetupStatus();
      // const updatedSetup = {
      //   ConfigurationExist: true,
      //   TeamsBotEnabled: true,
      //   AuthorizationGroupsCreated: true,
      //   AdminsAdded: false,
      //   AuthorizationGroups: {
      //     Admins: "joo",
      //     SuperUsers: "jppp",
      //     Users: "juuu",
      //   },
      // };
      setSetupStatus(updatedSetup);
    } catch (error) {
      console.error("Error creating Groups:", error);
    } finally {
      setIsLoadingCreateGroups(false); // Stop loading regardless of the result
      // checkAndCompleteSetup();
    }
  };

  const handleSetAdmins = async () => {
    setIsLoadingSetAdmins(true);
    const delayPromise = new Promise((resolve) => setTimeout(resolve, 1000));
    try {
      await Promise.all([delayPromise, postInitialSetupAdmins(selectedAdminUsers)]);
      const updatedSetup = await getInitialSetupStatus();
      // const updatedSetup = {
      //   ConfigurationExist: true,
      //   TeamsBotEnabled: true,
      //   AuthorizationGroupsCreated: true,
      //   AdminsAdded: true,
      //   AuthorizationGroups: {
      //     Admins: "",
      //     SuperUsers: "",
      //     Users: "",
      //   },
      // };
      setSetupStatus(updatedSetup);
    } catch (error) {
      console.error("Error inviting users:", error);
    } finally {
      setIsLoadingSetAdmins(false);
      setIsModalOpen(true);
      // checkAndCompleteSetup();
    }
  };

  // const checkAndCompleteSetup = () => {
  // Check if all setup steps are completed
  // if (setupStatus.TeamsBotEnabled && setupStatus.AuthorizationGroupsCreated && setupStatus.AdminsAdded && isModalClosed) {
  // Call the onSetupComplete function passed from the parent component
  // onSetupComplete();
  // }
  // };

  // useEffect(() => {
  // Call checkAndCompleteSetup on component mount to handle cases where the setup might already be complete
  // checkAndCompleteSetup();
  // }, [isModalClosed]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    // setIsModalClosed(true);
    localStorage.setItem("hasViewedAdminWelcome", "false");
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  // useEffect(() => {
  //   setSelectedAdminUsers(selectedAdminUsers);
  // }, [setSelectedAdminUsers]);

  return (
    <Stack>
      <Stack.Item align="stretch" className="install-wrapper">
        <h1 className="text-style--Large-title mb-40">{t("Setup")}</h1>

        <div className="install-intro mb-40">
          <img src={deliwiLogoRound} className="icon-shadow" alt="Deliwi AI" />
          <div className="install-intro--texts">
            <div className="install-intro--top">
              <h2 className="text-style--Subtitle1 text-color--brand10 install-intro--title">{t("WelcomeToDeliwiAI")}</h2>
              <p className="text-style--Body1">{t("TheVeryFirstThingToDo")}</p>
            </div>
            <div className="install-intro--bottom">
              <p className="text-style--Body1 text-style--bold">{t("Why")}</p>
              <p className="text-style--Body1">{t("WhyDeliwiAI")}</p>
            </div>
          </div>
        </div>
        <div className="admin-box">
          <h2 className="text-style--Main-title mb-24">{t("FirstSteps")}</h2>

          <div className="install__steps">
            <div className="install__steps--step">
              <div className="install__steps--step-icon">
                <img src={stepIcon1} className="icon-size-62 icon-shadow" alt="" />
              </div>
              <div className="install__steps--step-content">
                <h3 className="text-style--Title2 mb-8 text-color--black">{t("EnableChatbot")}</h3>
                <div className="install__steps--step-content--why mb-40">
                  <p className="text-style--Body1 text-color--brand10">
                    <strong>{t("Why")}</strong>
                  </p>
                  <p className="text-style--Body1 text-color--brand10">{t("EnableChatbotWhy")}</p>
                </div>
                <HelpDialog title={t("Help.EnableChatbot.Title")} dialogContent={t("Help.EnableChatbot.Content")} className="mb-16">
                  <p className="text-style--Body1 text-color--grey44">{t("EnableChatbot")}</p>
                </HelpDialog>
                <div className="install__steps--step-content--btnspin">
                  <button className={`install__button text-style--Subtitle2 ${setupStatus.TeamsBotEnabled ? "enabled" : "disabled"}`} onClick={handleToggleTeams}>
                    {setupStatus.TeamsBotEnabled ? "Chatbot for Teams enabled" : "Enable Chatbot for Teams"}
                  </button>
                  {isLoadingToggleTeams ? <div className="deliwi-spinner" /> : ""}
                </div>
              </div>
            </div>
            <div className={`install__steps--step ${setupStatus.TeamsBotEnabled ? "" : "disabled"}`}>
              <div className="install__steps--step-icon">
                <img src={stepIcon2} className="icon-size-62 icon-shadow" alt="" />
              </div>
              <div className="install__steps--step-content">
                <h3 className="text-style--Title2 mb-8 text-color--black">{t("CreateDeliwiAIUserGroupsEntra")}</h3>
                <div className="install__steps--step-content--why mb-40">
                  <p className="text-style--Body1 text-color--brand10">
                    <strong>{t("Why")}</strong>
                  </p>
                  <p className="text-style--Body1 text-color--brand10">{t("CreateDeliwiAIUserGroupsWhy")}</p>
                </div>
                <HelpDialog title={t("Help.EntraID.Title")} dialogContent={t("Help.EntraID.Content")} className="mb-24">
                  <p className="text-style--Body1 text-color--grey44">{t("DefineNamesEntraSecurityGroups")}</p>
                </HelpDialog>
                <Stack className="install__steps--inputs mb-40">
                  <TextField name="Admins" label="Admins" value={setupStatus.AuthorizationGroups.Admins !== "" ? setupStatus.AuthorizationGroups.Admins : textFieldValues.Admins} onChange={handleInputChange} disabled={setupStatus.AuthorizationGroupsCreated} className={setupStatus.AuthorizationGroupsCreated ? "disabledTextField" : ""} onRenderSuffix={() => (setupStatus.AuthorizationGroupsCreated ? <Icon iconName="CheckMark" className="checkmarkIcon" /> : null)} />

                  <TextField name="SuperUsers" label="SuperUsers" value={setupStatus.AuthorizationGroups.SuperUsers !== "" ? setupStatus.AuthorizationGroups.SuperUsers : textFieldValues.SuperUsers} onChange={handleInputChange} disabled={setupStatus.AuthorizationGroupsCreated} className={setupStatus.AuthorizationGroupsCreated ? "disabledTextField" : ""} onRenderSuffix={() => (setupStatus.AuthorizationGroupsCreated ? <Icon iconName="CheckMark" className="checkmarkIcon" /> : null)} />

                  <TextField name="Users" label="Users" value={setupStatus.AuthorizationGroups.Users !== "" ? setupStatus.AuthorizationGroups.Users : textFieldValues.Users} onChange={handleInputChange} disabled={setupStatus.AuthorizationGroupsCreated} className={setupStatus.AuthorizationGroupsCreated ? "disabledTextField" : ""} onRenderSuffix={() => (setupStatus.AuthorizationGroupsCreated ? <Icon iconName="CheckMark" className="checkmarkIcon" /> : null)} />
                </Stack>
                <div className="install__steps--step-content--btnspin">
                  <button className={`install__button text-style--Subtitle2 ${setupStatus.AuthorizationGroupsCreated ? "enabled" : "disabled"} ${!textFieldValues.Admins || !textFieldValues.SuperUsers || !textFieldValues.Users ? "not-active" : ""}`} onClick={!textFieldValues.Admins || !textFieldValues.SuperUsers || !textFieldValues.Users ? undefined : handleCreateGroups}>
                    {t("CreateGroups")}
                  </button>
                  {isLoadingCreateGroups ? <div className="deliwi-spinner" /> : ""}
                </div>
              </div>
            </div>
            <div className={`install__steps--step ${setupStatus.AuthorizationGroupsCreated ? "" : "disabled"}`}>
              <div className="install__steps--step-icon">
                <img src={stepIcon3} className="icon-size-62 icon-shadow" alt="" />
              </div>
              <div className="install__steps--step-content pb-0">
                <h3 className="text-style--Title2 mb-8 text-color--black">{t("AddAdminUsersDeliwiAi")}</h3>
                <div className="install__steps--step-content--why mb-40">
                  <p className="text-style--Body1 text-color--brand10">
                    <strong>{t("Why")}</strong>
                  </p>
                  <p className="text-style--Body1 text-color--brand10">{t("AddAdminUsersDeliwiAiWhy")}</p>
                </div>
                <HelpDialog title={t("Help.AddAdmins.Title")} dialogContent={t("Help.AddAdmins.Content")} className="mb-24">
                  <p className="text-style--Body1 text-color--grey44">{t("SelectAdminUserOrUsers")}</p>
                </HelpDialog>{" "}
                <div className="mb-40">
                  <PeoplePicker ref={peoplePickerRef} onUserSelect={handleUserSelection} pickerType="setup" />
                </div>
                <div className="install__steps--step-content--btnspin">
                  <button className={`install__button text-style--Subtitle2 ${setupStatus.AdminsAdded ? "enabled" : "disabled"} ${selectedAdminUsers.length === 0 ? "not-active" : ""}`} onClick={selectedAdminUsers.length === 0 ? undefined : handleSetAdmins}>
                    {t("AddSelectedUsersAsAdmins")}
                  </button>

                  {isLoadingSetAdmins ? <div className="deliwi-spinner" /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack.Item>
      <Dialog
        hidden={!isModalOpen}
        minWidth={640}
        dialogContentProps={{
          showCloseButton: true,
          title: t("SetupCompleted"),
        }}
        modalProps={{
          isBlocking: true,
          className: "admin-dialog",
        }}
      >
        <div className="box-gray mb-32">
          <p className="text-style--Body1 text-style--semibold mb-16">{t("SetupHasBeenCompletedSuccessfully")}</p>
          <div className="info-text">
            <p className="text-style--semibold">{t("AdminUsersHaveBeenAddedAndInvitationsSent")}</p>
          </div>
        </div>
        <DialogFooter>
          <DeliwiButton onClick={handleModalClose} text={t("CloseTheSetup")} className="button-style--primary button-size--large" />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};
