import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconClose = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C15.5229 0 20 4.47715 20 10C20 15.5229 15.5229 20 10 20C4.47715 20 0 15.5229 0 10C0 4.47715 4.47715 0 10 0ZM10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM7.26179 6.39215L7.34835 6.46446L10 9.11611L12.6516 6.46446C12.8686 6.24751 13.2054 6.2234 13.449 6.39215L13.5355 6.46446C13.7525 6.68142 13.7766 7.0182 13.6079 7.26179L13.5355 7.34835L10.8839 10L13.5355 12.6516C13.7525 12.8686 13.7766 13.2054 13.6079 13.449L13.5355 13.5355C13.3186 13.7525 12.9818 13.7766 12.7383 13.6079L12.6516 13.5355L10 10.8839L7.34835 13.5355C7.13139 13.7525 6.79461 13.7766 6.55102 13.6079L6.46446 13.5355C6.24751 13.3186 6.2234 12.9818 6.39215 12.7383L6.46446 12.6516L9.11611 10L6.46446 7.34835C6.24751 7.13139 6.2234 6.79461 6.39215 6.55102L6.46446 6.46446C6.68142 6.24751 7.0182 6.2234 7.26179 6.39215Z" fill="white" />
      </svg>
    </span>
  );
};
