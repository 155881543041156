import { ICustomIconProps } from "interfaces/ICustomIconProps";

export const IconHatGraduation = (props: ICustomIconProps) => {
  return (
    <span className={`icon--general ${props.className || ""}`}>
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Hat Graduation">
          <path
            id="Shape"
            d="M14.5635 5.49516C16.3531 4.34797 18.6469 4.34797 20.4365 5.49516L31.7594 12.7535C32.0601 12.9462 32.2426 13.2783 32.2442 13.6355C32.2457 13.9927 32.0661 14.3264 31.767 14.5218L27.3295 17.4209V25.2247C27.3295 25.4526 27.2555 25.6743 27.1188 25.8566L27.1173 25.8586L27.1156 25.8609L27.1113 25.8666L27.0997 25.8816C27.0937 25.8893 27.0864 25.8985 27.078 25.909C27.0738 25.9144 27.0692 25.9201 27.0643 25.9262C27.0352 25.9622 26.9951 26.0104 26.9437 26.0691C26.8411 26.1864 26.6932 26.3458 26.4983 26.533C26.1085 26.9071 25.5287 27.3939 24.7439 27.8769C23.1673 28.8471 20.7841 29.7884 17.5 29.7884C14.216 29.7884 11.8328 28.8471 10.2561 27.8769C9.47133 27.3939 8.89155 26.9071 8.50177 26.533C8.30679 26.3458 8.15893 26.1864 8.05628 26.0691C7.99642 26.0006 7.93752 25.9312 7.88272 25.8586C7.88059 25.8558 7.88121 25.8566 7.88121 25.8566C7.74449 25.6743 7.67058 25.4526 7.67058 25.2247V17.4209L4.86217 15.5861V23.1184C4.86217 23.7 4.39065 24.1716 3.80901 24.1716C3.22737 24.1716 2.75586 23.7 2.75586 23.1184V13.64C2.75586 13.2602 2.95691 12.9274 3.25839 12.7421L14.5635 5.49516ZM20.4761 21.8984C18.6679 23.0798 16.3321 23.0798 14.5239 21.8984L9.77689 18.7971V24.8298C9.82856 24.8838 9.8897 24.9456 9.96045 25.0135C10.2563 25.2975 10.7187 25.6884 11.3601 26.083C12.6357 26.8681 14.6407 27.6821 17.5 27.6821C20.3594 27.6821 22.3643 26.8681 23.64 26.083C24.2813 25.6884 24.7437 25.2975 25.0396 25.0135C25.1103 24.9456 25.1715 24.8838 25.2231 24.8298V18.7971L20.4761 21.8984ZM19.2998 7.26841C18.2029 6.56529 16.7971 6.56529 15.7002 7.26841L5.74739 13.6485L15.676 20.1351C16.7842 20.8592 18.2158 20.8592 19.3241 20.1351L29.2526 13.6485L19.2998 7.26841Z"
            fill="#020404"
          />
        </g>
      </svg>
    </span>
  );
};
