import { TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
// The icons are in use but the way IIconProps is setting them is causing this
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "assets/styles/components/EditInterests.scss";
import { UserMyInterestsEditAddInterest } from "./UserMyInterestsEditAddInterest";
import { IconDismiss } from "../../Icons/IconDismiss";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export const UserMyInterestsEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;
  const [interests, setInterests] = useState<string[]>([] as string[]);
  const [interestsDescription, setInterestsDescription] = useState<string | undefined>(undefined);
  const saveNewValues = () => {
    const interestsToSave = { Description: interestsDescription, Keywords: interests };

    const updatedProfile = {
      ...shadowUserProfile,
      Interests: interestsToSave,
    } as IUserProfile;

    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };
  // console.log(shadowUserProfile);
  useEffect(() => {
    setInterests(shadowUserProfile.Interests?.Keywords || []);
    setInterestsDescription(shadowUserProfile.Interests?.Description || "");
  }, []);

  const handleRemove = (Name: string) => {
    const fSkills = interests.filter((i) => i !== Name);
    setInterests(fSkills);
  };

  const handleDescriptionChange = (newValue: string) => {
    // console.log(newValue);
    setInterestsDescription(newValue);
  };

  // console.log(interestsDescription);

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("MyInterests")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <div className="form--container x-large mb-40">
        <UserMyInterestsEditAddInterest interests={interests} setInterests={setInterests} />
        {!!interests && (
          <div className="my-interests--list">
            {interests.map((interest, index) => {
              return (
                <div key={index} className="my-interests--list--item">
                  <span>{interest}</span>
                  <button className="remove-item" onClick={() => handleRemove(interest)}>
                    <IconDismiss className="icon-delete" />
                  </button>
                </div>
              );
            })}
          </div>
        )}
        <div className="form--field--wrapper col-12">{interestsDescription !== undefined && <TextField label={t("ShortDescriptionOfInterestsEditLabel")} id="interests-description" name="interests-description" multiline rows={4} defaultValue={interestsDescription} onChange={(event, newValue) => newValue !== undefined && handleDescriptionChange(newValue)} />}</div>
      </div>
      <div className="edit-dialog--buttons">
        <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
          <IconClose className="icon-size--20" />
          {t("Cancel")}
        </DeliwiButton>
        <DeliwiButton type="submit" className="button-style--primary button-size--large" onClick={saveNewValues} disabled={props.saving}>
          <IconCheckmark />
          {t("SaveChanges")}
        </DeliwiButton>
      </div>
    </>
  );
};
