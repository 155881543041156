import { Label, Slider, TextField } from "@fluentui/react";
import { DeliwiButton } from "components/DeliwiButton";
import { IUserProfile, IUserProfileEditForm } from "../../../interfaces/IUserProfile";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { HelpDialog } from "../../HelpDialog";
import { IconCheckmark } from "components/Icons/IconCheckmark";
import { IconClose } from "../../Icons/IconClose";

export interface IFutureDevelopmentFormProps {
  WorkloadPrediction: number;
  WhatWouldYouLikeToDoIn3Years: string;
  DreamPositionOrJobIn5Years: string;
}

export const UserFutureDevelopmentEdit = (props: IUserProfileEditForm) => {
  const shadowUserProfile = props.userProfileData;

  const validationSchema = Yup.object({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      WorkloadPrediction: shadowUserProfile.WorkloadPrediction || 100,
      WhatWouldYouLikeToDoIn3Years: shadowUserProfile.WhatWouldYouLikeToDoIn3Years || "",
      DreamPositionOrJobIn5Years: shadowUserProfile.DreamPositionOrJobIn5Years || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      saveNewValues(values);
    },
  });

  const saveNewValues = (values: IFutureDevelopmentFormProps) => {
    const updatedProfile = {
      ...shadowUserProfile,
      WorkloadPrediction: values.WorkloadPrediction,
      WhatWouldYouLikeToDoIn3Years: values.WhatWouldYouLikeToDoIn3Years,
      DreamPositionOrJobIn5Years: values.DreamPositionOrJobIn5Years,
    } as IUserProfile;
    props.userProfileSetter(updatedProfile);
    props.saveProfile(updatedProfile);
    props.hidePopup();
  };

  return (
    <>
      <div className="edit-dialog--header form--heading--wrapper">
        <h2 className="form--heading">{t("FutureDevelopment")}</h2>
        <div className="close-preview--wrapper">
          <ChromeCloseIcon className="close-edit-popup" onClick={() => props.hidePopup()}></ChromeCloseIcon>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form--container mb-40">
          <div className="form--fields-container form--fields-container-flexcol">
            <div className="form--field--wrapper">
              <HelpDialog className="" dialogContent={t("Help.EditForms.FutureDevelopment.WorkloadPrediction.Content")} title={t("Help.EditForms.FutureDevelopment.WorkloadPrediction.Title")}>
                <Label>{t("WorkloadPredictionForNext3Months")}</Label>
              </HelpDialog>
              <Slider
                min={0}
                max={100}
                step={5}
                defaultValue={formik.values.WorkloadPrediction}
                onChange={(value) => {
                  formik.setFieldValue("WorkloadPrediction", value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="form--field--wrapper">
              <TextField label={t("WhatWouldYouLikeToDoIn3Years")} id="WhatWouldYouLikeToDoIn3Years" name="WhatWouldYouLikeToDoIn3Years" multiline autoAdjustHeight rows={4} defaultValue={formik.values.WhatWouldYouLikeToDoIn3Years} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>

            <div className="form--field--wrapper">
              <TextField label={t("DreamPositionOrJobIn5Years")} id="DreamPositionOrJobIn5Years" name="DreamPositionOrJobIn5Years" multiline autoAdjustHeight rows={4} defaultValue={formik.values.DreamPositionOrJobIn5Years} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            </div>
          </div>
        </div>
        <div className="edit-dialog--buttons">
          <DeliwiButton className="button-style--outline button-size--large" onClick={() => props.hidePopup()}>
            <IconClose className="icon-size--20" />
            {t("Cancel")}
          </DeliwiButton>
          <DeliwiButton type="submit" className="button-style--primary button-size--large" disabled={props.saving}>
            <IconCheckmark />
            {t("SaveChanges")}
          </DeliwiButton>
        </div>
      </form>
    </>
  );
};
