import "assets/styles/components/WorkLoadMeter.scss";

export interface IWorkLoadMeterProps {
  workLoad: number;
}

export const WorkLoadMeter = (props: IWorkLoadMeterProps) => {
  return (
    <div className="work-load-meter">
      <div className="work-load-meter--slider">
        <span className="work-load-meter--slider--highlighted" style={{ width: props.workLoad + "%" }}></span>
        <div className="work-load-meter--slider--indicator--wrapper" style={{ left: props.workLoad + "%" }}>
          <span className="work-load-meter--slider--indicator">{props.workLoad}%</span>
        </div>
      </div>
    </div>
  );
};
