import {useEffect, useState} from "react";
import {t} from "i18next";

import {Modal, IconButton, IIconProps} from "@fluentui/react";
import {DeliwiButton} from "components/DeliwiButton";

import {IconArrowForward} from "components/Icons/IconArrowForward";
import IconDiamond from "assets/icons/icon-diamond.svg";
import IconEye from "assets/icons/icon-eye.svg";
import IconQuestion from "assets/icons/icon-question.svg";
import myProfileWelcomeImg from "assets/images/myprofile-welcome.png";

const cancelIcon: IIconProps = {iconName: "Cancel"};

export const MyProfileWelcome = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        const hasViewedProfileWelcome = localStorage.getItem("hasViewedProfileWelcome");

        if (hasViewedProfileWelcome !== "true") {
            setIsModalOpen(true);
        }
    }, []);

    const handleCloseModal = () => {
        localStorage.setItem("hasViewedProfileWelcome", "true"); // Set the flag in local storage
        setIsModalOpen(false); // Close the modal
    };

    return (
        <>
            <Modal isOpen={isModalOpen} onDismiss={handleCloseModal} isBlocking={true}
                   className="myprofile-welcome text-color--black" containerClassName="myprofile-welcome__modal">
                <div className="myprofile-welcome__modal--header mb-24">
                    <div className="tag--candy">{t("Myprofile")}</div>
                    <IconButton iconProps={cancelIcon} ariaLabel="Close popup modal" onClick={handleCloseModal}/>
                </div>
                <h1 className="text-style--Main-title mb-16">{t("WelcomeMyProfile")}</h1>
                <p className="text-style--Subtitle1 text-color--brand10 fw-400 mb-32">{t("WelcomeMyProfileIntro")}</p>
                <div className="myprofile-welcome__modal--columns mb-60 ">
                    <div className="myprofile-welcome__modal--textcols">
                        <div className="">
                            <img src={IconEye} className="icon-size-62 icon-shadow mb-16" alt=""/>
                            <h2 className="text-style--Title2 fw-600 mb-16">{t("BecomeVisible")}</h2>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("BecomeVisible1")}</p>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("BecomeVisible2")}</p>
                        </div>
                        <div className="">
                            <img src={IconDiamond} className="icon-size-62 icon-shadow mb-16" alt=""/>
                            <h2 className="text-style--Title2 fw-600 mb-16">{t("ShowYourTalent")}</h2>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("ShowYourTalent1")}</p>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("ShowYourTalent2")}</p>
                        </div>
                        <div className="">
                            <img src={IconQuestion} className="icon-size-62 icon-shadow mb-16" alt=""/>
                            <h2 className="text-style--Title2 fw-600 mb-16">{t("HowItWorks")}</h2>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("HowItWorks1")}</p>
                            <p className="text-style--Body2 mb-16 text-color--brand20">{t("HowItWorks2")}</p>
                        </div>
                    </div>
                    <div className="">
                        <img src={myProfileWelcomeImg} alt=""/>
                    </div>
                </div>
                <DeliwiButton onClick={handleCloseModal} className="button-style--primary button-size--large ml-auto">
                    <IconArrowForward/>
                    {t("GoToMyProfile")}
                </DeliwiButton>
            </Modal>
        </>
    );
};
